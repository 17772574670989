const dictionary: Record<string, string> = {
	'Customer information not found': 'Datos del cliente no encontrados',
	'Account subscription expired, renew to continue.': 'La suscripción a la cuenta expiró, renuévela para continuar',
	'Account subscription cancelled, renew to continue.': 'Suscripción de cuenta cancelada, renovar para continuar.',

	Warning: 'Aviso',
	Yes: 'Sí',
	No: 'No',
	Home: 'Inicio',

	Companies: 'Empresas',
	'Corrupted file': 'Archivo corrompido',
	'Extracted data': 'Datos extraídos',
	'Not processing': 'Sin procesar',
	Processing: 'Procesando',
	Processed: 'Procesada',
	'In queue': 'En fila',
	'Under maintenance': 'En mantenimiento',

	// Upload
	'Send video': 'Subir video',
	'Add files or drag': 'Agregar archivos o arrastrar',
	'Unsupported file': 'Archivo no soportado',
	'Drop files here': 'Suelte archivos aquí',
	'Unsupported video format': 'Formato de video no compatible',
	and: 'y',
	'is larger than': 'Es mas grande que',
	'Max size': 'Tamaño máximo',
	Blur: 'Desenfocar',
	'Are you sure you want to exit? You have uploads in process.':
		'¿Estás seguro de que quieres salir? Tienes una carga en proceso.',
	' or too many files': ' o muchos archivos',
	'Files number exceeded': 'Número de archivos excedidos',
	'Max files': 'Máximo de archivos',
	'User created successfully': 'Usuario creado con éxito',
	'File duplicated': 'Archivo duplicado',

	// Select project
	'Select the desired company below': 'Seleccione la empresa deseada',
	'Select the desired industrial site below': 'Seleccione el centro de trabajo deseado',

	'Create a new company': 'Crear una nueva empresa',
	'Create a new industrial site': 'Crear un nuevo centro de trabajo',

	'Enter a name for the company': 'Introduzca un nombre de empresa',
	'Enter a name for the industrial site': 'Introduzca un nombre para el centro de trabajo',

	'Company created successfully': 'Empresa creada con éxito',
	'Industrial site created successfully': 'Centro de trabajo creada con éxito',

	'There are no registered companies': 'No hay empresas registradas',
	'There are no registered industrial sites': 'No hay centro de trabajo registrado',

	'Congratulations! Now create an industrial site that belongs to this company.':
		'¡Felicidades! Ahora cree el centro de trabajo que pertenece a esta empresa y comience a usar la plataforma',
	'Create industrial site': 'Crear centro de trabajo',

	'Welcome to Kinebot, create your first company': 'Bienvenido a Kinebot, crea tu primera empresa',
	'Create company': 'Crear empresa',

	'Ergonomic tools': 'Herramientas ergonómicas',
	'Select the most suitable tool for your analysis': 'Seleccione la herramienta más adecuada para su análisis',
	'Biomechanical analysis': 'Análisis biomecánico',
	'Load analysis': 'Análisis de carga',
	'Repeatability analysis': 'Análisis de repetibilidad',
	'Tool not set yet': 'Herramienta aún no configurada',

	'Go back': 'Regresar',
	Back: 'Regresar',
	Create: 'Crear',

	// Report/UploadList
	Workstation: 'Puesto de trabajo',
	Description: 'Descripción',
	Size: 'Tamaño',
	'Upload date': 'Fecha de carga',
	Sector: 'Sector',
	Actions: 'Acciones',
	'Not assigned': 'No asignado',
	'Heads up!': '¡Aviso!',
	'Heads up! Want to delete this file?': '¡Aviso! ¿Quieres eliminar este archivo?',
	'Force/Load movement data': 'Datos de movimiento con fuerza/carga',
	'Heads up! Want to delete those files?': '¡Aviso! ¿Quieres eliminar estes archivos?',
	Rating: 'Clasificación',
	Repetitions: 'Repeticiones',
	Legs: 'Piernas',
	Diagnostic: 'Diagnóstico',
	'Analysis results': 'Resultados de análisis',

	'Not found': 'No encontrado',
	'Failed to fetch data': 'Error al obtener los datos',
	//'Risk level of the job': 'Grado de riesgo del trabajo',
	'Average exposure of the workplace': 'Exposición media del puesto de trabajo',
	'Unable to show results.': 'No se pueden mostrar los resultados',

	'Load less than 2 kg (intermittent)': 'Carga menos de 2kg (intermitente)',
	'Load from 2 kg to 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg to 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estática o repetitiva)',
	'If more than 10 kg or repeated or rapid build up of force': 'Carga mayor 10 kg de carga repetitiva o golpes',
	'Repetitive load or rapid build up of force greater than 10 kg': 'Carga repetitiva mayor de 10 kg o golpe',
	'Legs and feet supported and with equal load distribution':
		'Piernas y pies soportados y con igual distribución de carga',
	'Legs without support': 'Piernas sin apoyo',

	Reports: 'Reportes',
	'Select the data visualization model': 'Seleccione el modelo de visualización de datos',
	Angle: 'Ángulo',
	'Angle in degrees (°)': 'Ángulo en grados (°)',
	'Time in seconds (s)': 'Tiempo en segundos (s)',
	'Movement by score': 'Movimiento por puntuación',
	Parameters: 'Parámetros',

	'Push and pull': 'Empujar y arraste',
	'KIM - Push and pull': 'KIM - Empuje y arraste',

	'KIM - PP Report Results': 'KIM - EA Reporte de resultados',
	meters: 'metros',
	'Temporal distribution': 'Distribución del tiempo',
	Distance: 'Distancia',
	'Work condition': 'Condiciones de trabajo',
	'Work conditions': 'Condiciones de trabajo',
	'Working conditions': 'Condiciones de trabajo',
	'Vehicle properties': 'Propiedades del vehículo',
	Vehicle: 'Vehículo',
	'Distance or duration': 'Distancia o duración',

	'Select one of the options below': 'Seleccione una de las siguientes opciones',
	'Short distances': 'Distancias cortas',
	'Long distances': 'Distancias largas',
	Time: 'Tiempo',
	Mass: 'Masa',
	Precision: 'Precision',
	'Worker gender': 'Género del trabajador',
	'Pushing / Pulling in pares': 'Empujando / Estirando en pares',
	Male: 'Masculino',
	Female: 'Femenino',
	'By distance': 'Por la distancia',
	'By duration': 'Por la duración',
	'Distance per work day (in meters)': 'Distancia por día de trabajo (en metros)',
	'Duration per work day (in minutes)': 'Duración por día de trabajo (en minutos)',
	'≤ 40': '≤ 40',
	'≤ 200': '≤ 200',
	'≤ 400': '≤ 400',
	'≤ 800': '≤ 800',
	'≤ 1,200': '≤ 1.200',
	'≤ 1,800': '≤ 1.800',
	'≤ 2,500': '≤ 2.500',
	'≤ 4,200': '≤ 4.200',
	'≤ 6,300': '≤ 6.300',
	'≤ 8,400': '≤ 8.400',
	'≤ 11,000': '≤ 11.000',
	'≤ 15,000': '≤ 15.000',
	'≤ 20,000': '≤ 20.000',
	'≤ 1': '≤ 1',
	'≤ 5': '≤ 5',
	'≤ 10': '≤ 10',
	'≤ 20': '≤ 20',
	'≤ 30': '≤ 30',
	'≤ 45': '≤ 45',
	'≤ 60': '≤ 60',
	'≤ 100': '≤ 100',
	'≤ 150': '≤ 150',
	'≤ 210': '≤ 210',
	'≤ 270': '≤ 270',
	'≤ 360': '≤ 360',
	'≤ 480': '≤ 480',
	'Select one of the vehicles': 'Seleccione uno de los vehículos de transporte',
	Barrows: 'Carretillas',
	Carriages: 'Carruaje',
	'Overhead conveyors': 'Transportadores',
	'Overhead cranes': 'Gruas',
	'Choose an option': 'Elige una opción',
	'Reference image: only swivel castors': 'Imagen de referencia: ruedas móviles solamente',
	'Reference image: with fixed castors or lockable swivel castors':
		'Imagen de referencia: con ruedas fijas o bloqueables',
	'Reference image: pedestrian-controlled': 'Imagen de referencia: controlado por peatones',
	'Driveway condition': 'Estado de la acera',
	'Inclination or stairs': 'Pendiente o escaleras',
	'Select the mass to be transported (in kg)': 'Seleccione la masa a transportar (en kg)',
	'Driveway completely level, smooth, solid, dry, without inclinations':
		'Pavimento completamente nivelado, liso, sólido, seco, sin pendientes',
	'Driveway mostly smooth and level, with small damaged spots/faults, without inclinations':
		'Acera casi lisa y nivelada, con pequeños daños en algunos lugares, sin pendientes',
	'Mixture of cobbles, concrete, asphalt, slight inclinations, dropped kerb':
		'Mezcla de adoquines, hormigón, asfalto, pendientes ligeras, bordillo bajo',
	'Mixture of roughly cobbled, hard sand, slight inclinations, small edges/sills':
		'Mezcla áspera de adoquines, arena dura, pendientes ligeras y bordes/alféizares pequeños',
	'Earth or roughly cobbled driveway, potholes, heavy soiling, slight inclinations, landings, sills':
		'Mezcla áspera de tierra o adoquines, baches, tierra pesada, pendientes pequeñas, descansos, umbrales',
	'Inclinations of 2 ≤ 4° (4 ≤ 8%)': 'Pendientes de 2 ≤ 4° (4 ≤ 8%)',
	'Inclinations of 5 ≤ 10° (9 ≤ 18%)': 'Pendientes de 5 ≤ 10° (9 ≤ 18%)',
	'Stairs, inclinations > 10° (18%)': 'Escaleras, pendientes > 10° (18%)',
	'No significant inclination': 'Sin pendiente relevante',
	'≤ 50': '≤ 50',
	'≤ 300': '≤ 300',
	'≤ 600': '≤ 600',
	'≤ 1,000': '≤ 1.000',
	'≤ 1,300': '≤ 1.300',
	'> 1,300': '> 1.300',
	'Select the appropriate options': 'Seleccione la opción apropiada',
	'Unfavorable working conditions - more than one can be selected':
		'Condiciones laborales desfavorables - se puede seleccionar más de una opción',
	'Unfavorable vehicle properties - more than one can be selected':
		'Propiedades desfavorables del vehículo - se puede seleccionar más de una opción',
	'Unfavorable working conditions': 'Condiciones laborales desfavorables',
	'Unfavorable vehicle properties': 'Propiedades desfavorables del vehículo',
	'Regularly significantly increased starting forces, because transport devices sink into the ground or get wedged':
		'Regularmente se requiere una fuerza considerable para iniciar el movimiento, debido a que el vehículo se hunde en el suelo o se atasca',
	'Frequent stops with braking': 'Paradas frecuentes con frenado',
	'Frequent stops without braking': 'Paradas frecuentes sin frenado',
	'Many changes of direction or curves, frequent maneuvering':
		'Muchos cambios de dirección o giros, maniobras frecuentes',
	'Load must be positioned precisely and stopped, driveway must be adhered to precisely':
		'La carga debe colocarse y asegurarse con precisión, adhiriéndose a un lugar exacto en la acera',
	'Increased movement speed (approx. 1.0 ≤ 1.3 m/s)': 'Alta velocidad de movimiento (aprox. 1.0 ≤ 1.3 m/s)',
	'None: there are no unfavorable working conditions': 'Ninguna: no existen condiciones laborales desfavorables',
	'No suitable handles or construction parts for applying force': 'No hay manijas o partes aptas para aplicar fuerza',
	'No brake when driving on inclinations > 2° (> 3%)': 'Sin frenos al manipular o inclinarse > 2° (> 3%)',
	'Unadjusted castors (e.g. too small on soft or uneven floor)':
		'Ruedas mal ajustadas (ej.: demasiado pequeño o no coincide con el suelo)',
	'Defective castors (worn-out, rubbing, stiff, air pressure too low)':
		'Ruedas defectuosas (desgastadas, rozando, atascadas, presión de aire demasiado baja)',
	'None: there are no unfavorable properties of the transport devices':
		'Ninguna: no hay propiedades desfavorables del vehículo',

	'Trunk upright or slightly inclined forward, no twisting / Force application height can be selected freely / No hindrance for the legs':
		'Torso erguido o ligeramente inclinado hacia adelante, sin torsión / Fuerza de aplicación a la altura libremente seleccionada / Sin molestias para las piernas',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side / Fixed force application height ranging from 0.9 – 1.2 m / None or only slight hindrance for the legs / Predominantly pulling':
		'Cuerpo inclinado en la dirección del movimiento o ligeramente torcido tirando de la carga hacia los lados / Fuerza aplicada a una altura fija de 0,9 - 1,2 m / Ninguna o pequeña complicaciones en las piernas / Predominantemente estirando',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view / Significant hindrance for the legs / Constant twisting and/or lateral inclination of the trunk':
		'Posturas extrañas del cuerpo causadas por fuerza a una altura fija de < 0,9 o > 1,2 m, aplicación de fuerza lateral a un lado o con una visión significativamente bloqueada / Complicación considerable para las piernas / Torsión constante y/o flexión lateral del tronco',

	'Trunk upright or slightly inclined forward, no twisting':
		'Torso erguido o ligeramente inclinado hacia adelante, sin torsión',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side':
		'Cuerpo inclinado en la dirección del movimiento o ligeramente torcido tirando de la carga hacia los lados',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view':
		'Posturas extrañas del cuerpo causadas por fuerza a una altura fija de < 0,9 o > 1,2 m, aplicación de fuerza lateral a un lado o con una visión significativamente bloqueada',

	'Force application height can be selected freely': 'Fuerza de aplicación a la altura libremente seleccionada',
	'Fixed force application height ranging from 0.9 – 1.2 m': 'Fuerza aplicada a una altura fija de 0,9 - 1,2 m',
	'Significant hindrance for the legs': 'Complicación considerable para las piernas',

	'No hindrance for the legs': 'Sin molestias para las piernas',
	'None or only slight hindrance for the legs': 'Sin complicaciones en las piernas o menores',
	'Constant twisting and/or lateral inclination of the trunk': 'Torsión constante y/o flexión lateral del tronco',
	'Predominantly pulling': 'Predominantemente tirando',

	Postures: 'Posturas',
	'Direction of force': 'Dirección de la fuerza',
	Unfavorable: 'Desfavorable',
	'Score and risk assessment': 'Score y definición de riesgo',
	'Intensity of load: ': 'Intensidad de carga',
	'Probability of physical overload / Possible health consequences: ':
		'Probabilidad de sobrecarga física / Posibles consecuencias para la salud',
	'Probability of physical overload': 'Probabilidad de sobrecarga física',
	'Physical overload is unlikely / No health risk is to be expected.':
		'Sobrecarga física improbable / No se espera riesgo para la salud.',
	'Physical overload is unlikely': 'Sobrecarga física improbable',
	'No health risk is to be expected': 'No se espera riesgo para la salud',
	'Physical overload is possible for less resilient persons /  Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'La sobrecarga física es posible para personas menos resistentes / Fatiga, problemas menores de adaptación que se pueden compensar con tiempo de descanso',
	'Physical overload is possible for less resilient persons':
		'La sobrecarga física es posible para personas menos resistentes',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'Fatiga, problemas menores de adaptación que se pueden compensar con tiempo de descanso',
	'Physical overload is also possible for normally resilient persons / Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'La sobrecarga física es posible incluso para personas con resistencia normal / Trastornos (dolor), posibilidad de disfunción, reversible en la mayoría de los casos, sin manifestación morfológica',
	'Physical overload is also possible for normally resilient persons':
		'La sobrecarga física es posible incluso para personas con resistencia normal',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Trastornos (dolor), posibilidad de disfunción, reversible en la mayoría de los casos, sin manifestación morfológica',
	'Physical overload is likely / More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'Es probable una sobrecarga física / Marcadas alteraciones y/o disfunciones, daño estructural con significado patológico.',
	'Physical overload is likely': 'Es probable una sobrecarga física',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Marcadas alteraciones y/o disfunciones, daño estructural con significado patológico',
	'Measures: ': 'Medidas',
	' For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		' Para las personas menos resilientes, cambiar el lugar de trabajo y otras medidas preventivas pueden ser útiles.',
	' Workplace redesign and other prevention measures should be considered.':
		' Se debe considerar cambiar el lugar de trabajo y otras medidas preventivas.',
	' Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'Es necesario cambiar el lugar de trabajo. También se deben considerar otras medidas preventivas.',
	None: 'Ninguna',

	'Manual lifting': 'Levantamiento de cargas',
	'NIOSH / ISO 11228-1 - Manual lifting analysis': 'NIOSH / ISO 11228-1 - Análisis de levantamiento de cargas',

	Information: 'Información',
	'Body parts': 'Partes del cuerpo',
	Charts: 'Gráficos',

	'Industrial Site': 'Centro de trabajo',
	'Collection date': 'Fecha de recolección',
	'Risk range': 'Rango de riesgo',
	'Risk Range': 'Rango de riesgo',

	'Failed to fetch sectors': 'Error al obtener sectores',
	'Failed to create a new sector': 'No se pudo crear un nuevo sector',
	'Failed to fetch risk bands': 'Error al buscar bandas de riesgo',
	'Sector created successfully': 'Sector creado con éxito',

	'Select a sector': 'Seleccione un sector',
	'Insert a workstation': 'Insertar un puesto de trabajo',
	'Insert the date of collection': 'Introduzca la fecha de recogida',
	'Select a risk band': 'Seleccione un rango de riesgo',
	'Graphs of selected body parts': 'Gráfico de partes del cuerpo seleccionadas',

	Summary: 'Resumen',
	'Range of Risk': 'Rango de riesgo',
	'Job information': 'Información del trabajo',
	'File data': 'Datos del archivo',

	File: 'Archivo',
	Duration: 'Duración',
	'Relevant points': 'Puntos relevantes',
	'Most critical move': 'Movimiento más crítico',
	'Include conclusion': 'Incluir conclusión',
	'Include signature': 'Incluir Firma',
	'Insert conclusion': 'Incluir conclusión',
	'Risk track created successfully!': '¡Pista de riesgo creada con éxito!',
	'Create custom risk bands based on your analysis': 'Cree bandas de riesgo personalizadas basadas en su análisis',

	//Dashboard
	'Industrial site status': 'Estado del centro de trabajo',
	'Harmful postures': 'Posturas perjudiciales',
	'Inadequate postures': 'Posturas inapropiadas',
	'Workstation with good postures': 'Mensajes con buenas posturas',
	'Workers in real danger': 'Trabajadores en riesgo real',
	'Total of videos': 'Vídeos totales',
	'Processed minutes': 'Minutos procesados',
	'Critical sector': 'Sector crítico',
	'Registered sectors': 'Sectores registrados',
	'Exported reports': 'Reportes extraídos',
	'Sectors in alert': 'Sectores de alerta',
	'Main statistics': 'Estadísticas principales',
	'No. of videos by risk level': 'Total de videos por nivel de riesgo',
	Month: 'Mes',
	Year: 'Año',
	Day: 'Dia',
	'Assessments completed': 'Evaluaciones completas',
	'Videos by sector': 'Vídeos por sectores',
	'Percentage of videos by risk': 'Porcentaje de videos en riesgo',
	'Ergonomics Management': 'Gestión de la ergonomía',
	'Risk Quotation': 'Cotización de riesgo',
	'Total workstations': 'Puesto de trabajo totales',
	'Total pending evaluations': 'Total de evaluaciones pendientes',
	'Total evaluations consolidated': 'Total de evaluaciones completadas',
	Evaluations: 'Evaluaciones',
	'Not analyzed': 'No analizados',
	'Not analysed': 'No analizado',
	'There are no registered action plans': 'No hay planes de acción registrados',
	'Acceptable Risk': 'Riesgo aceptable',
	'Acceptable risk': 'Riesgo aceptable',
	'Moderate Risk': 'Riesgo moderado',
	'Moderate risk': 'Riesgo moderado',
	'High Risk': 'Alto riesgo',
	'High risk': 'Alto riesgo',
	'Very High Risk': 'Riesgo muy alto',
	'Very high risk': 'Riesgo muy alto',
	'Extreme and Imminent Risk': 'Riesgo extremo',
	'Serious and imminent risk': 'Riesgo extremo',
	'Serious and Imminent Risk': 'Riesgo extremo',
	'Serious risk': 'Riesgo extremo',
	'Low Risk': 'Riesgo leve',
	'Medium Risk': 'Riesgo moderado',

	// Dashboard Filter
	Cellule: 'Célula',
	'Select the Cellule': 'Seleccione la célula',
	'Select the Workstation': 'Seleccione el puesto de trabajo',
	'Working Population': 'Población trabajadora',
	Men: 'Hombres',
	Women: 'Mujer',
	Others: 'Otros',
	'Stress level': 'Nivel de estrés',
	'Risk Level': 'Nivel de riesgo',

	// Dashboard Tools Results
	Repetitiveness: 'Repetitividad',
	'Cargo Handling': 'Manipulación de la carga',
	'Work Organization': 'Organización del trabajo',
	'Score by workstation': 'Puntuación por puesto de trabajo',
	'Total evaluations': 'Evaluaciones totales',
	'Stress Level': 'Nivel de estrés',
	Low: 'Bajo',
	Average: 'Promedio',
	High: 'Alto',

	// Dashboard Movement by score
	'Risk per Workstation': 'Riesgo por puesto de trabajo',

	// Dashboard Workstations
	Workstations: 'Puesto de trabajo',
	'Higher workstation risk score': 'Puntuación de riesgo de puesto de trabajo más alta',
	// Dashboard Action Plans
	'Planned Actions': 'Acciones planeadas',
	Completed: 'Hecho',
	Concluded: 'Concluido',
	Ascending: 'Ascendente',
	'Deadline : ': 'Prazo: ',

	// Buttons
	Next: 'Siguiente',
	Previous: 'Regresar',
	delete: 'eliminar',
	Delete: 'Eliminar',

	'Do you really want to delete these tracks?': '¿Realmente desea eliminar estas pistas?',
	'Heads up! the deletion is permanent': '¡Alto! La eliminación es permanente',
	'Risk ranges deleted successfully': 'Rangos de riesgo eliminados con éxito',

	'Description (optional)': 'Descripción (opcional)',

	// Body parts
	Neck: 'Cuello',
	Trunk: 'Tronco',
	UpperArm: 'Hombro',
	LowerArm: 'Codo',

	'Customize bands of risk': 'Personalice los rangos de riesgo',
	'Create custom risk strips according to your needs.': 'Crea tiras de riesgo personalizadas según tus necesidades',

	Name: 'Nombre',
	'Industrial site name': 'Ingresa un nombre',
	'Enter a name': 'Introduzca un nombre válido',
	'Please enter a valid name': 'Introduzca un nombre válido',
	'Please enter a valid phone number': 'Por favor ingrese un número de teléfono válido',
	'Invalid phone number': 'Numero de telefono invalido',
	'Insert short description': 'Introduzca una breve descripción',
	'Add new band': 'Añadir niebla de banda',
	'Create new band': 'Crear nueva banda',
	'Minimum angle': 'Ángulo mínimo',
	'Maximum angle': 'Ángulo máximo',
	Coloring: 'Colorante',
	Remove: 'Para eliminar',

	//	Breadcrumb
	'Manage users': 'Administrar usuarios',
	Preferences: 'Preferencias',
	'My informations': 'Mis informaciones',
	'My plan': 'Mi plan',

	individual: 'Individual',
	team: 'Equipo',
	business: 'Negocio',
	pilot: 'Piloto',

	yearly: 'anual',
	monthly: 'mensal',
	Yearly: 'Anual',
	Monthly: 'Mensal',

	User: 'Usuario',
	Supervisor: 'Supervisor',
	Master: 'Maestro',
	Admin: 'Administrador',

	'Choose a plan different from your current plan': 'Elija un plan diferente a su plan actual',
	'Plan successfully canceled': 'Plan cancelado con éxito',
	'Password changed successfully': 'Contraseña alterada con éxito',

	'Customer plan not found': 'Plan de cliente no encontrado',
	'Customer data not found': 'Datos del cliente no encontrados',
	'User access not found': 'Permiso de usuario no encontrado',
	'File deleted successfully': 'Archivo eliminado con éxito',

	'Check the parameters entered': 'Verifique los datos ingresados',
	'Number of document characters are invalid': 'El número de caracteres del documento no es válido',
	'Invalid inserted CPF': 'CPF ingresado no válido',
	'Invalid inserted CNPJ': 'CNPJ ingresado no válido',

	'It is necessary to spend a day after contracting the service to change the plan':
		'Es necesario pasar un día después de contratar el servicio para cambiar de plan',

	'Forgot password': '¿Olvidaste tu contraseña?',
	'Log in': 'Acceso',
	'Please input your password!': '¡Por favor ingrese su contraseña!',
	'Invalid email or password': 'Correo electrónico o contraseña no válidos',
	'The input is not a valid e-mail!': 'Cuenta inactiva, actívela',
	'Please input your e-mail': 'Por favor ingrese su correo electrónico',
	'Inactive account, please activate': 'Cuenta inactiva, actívala',
	Send: 'Enviar',
	'User not found': 'Usuario no encontrado',
	'Success, check your email box': 'Éxito, revisa tu bandeja de entrada',
	'Password recovery': 'Restablecimiento de contraseña',
	'Token invalid': 'Solicitud no válida',
	'Token expired, generated a new one': 'La sesión expiró, por favor inténtalo de nuevo',
	'Please confirm your password!': 'Por favor, confirme su contraseña',
	'Confirm Password': 'Confirme su contraseña',
	'The two passwords that you entered do not match!': 'Las dos contraseñas no coinciden, compruebe',
	'Reset token': 'Recuperar',

	Continue: 'Continuar',
	Confirm: 'Confirmar',
	Cancel: 'Cancelar',
	flexion: 'Flexión',
	turned: 'Volcado',
	extension: 'Extensión',
	sideways: 'Lateralmente',
	crouched: 'En cuclillas',
	downed: 'Bajado',
	arm_raised: 'Brazo levantado',
	behind: 'Atrás',
	raised: 'Levantado',
	none: 'Ninguna',

	// Check-in Customer
	'Registered successfully!': '¡Registro completado con éxito!',
	'Just a little longer, check your e-mail inbox':
		'Ahora es el momento de revisar su bandeja de entrada de correo electrónico',
	Access: 'Acceso',
	'Kinebot - Terms of use': 'Kinebot-Términos de uso',
	'terms of use': 'términos de uso',
	"Welcome! Let's start": '¡Bienvenido, empecemos!',
	'Enter your phone number': 'Introduzca su teléfono',
	'Select your contry': 'Selecciona tu pais',
	State: 'Estado',
	'State:': 'Estado:',
	'Select your state': 'Seleccione su estado',
	'Select your city': 'Seleccione su ciudad',
	'Zip code': 'CEP',
	'Enter your zipcode': 'Introduzca su código postal',
	'Enter your address': 'Ingrese su dirección',
	'Enter your street number': 'Ingrese el número de la calle',
	'Coupon (optional)': 'Cupón (opcional)',
	Coupon: 'Cupón',

	// User Activation
	'There are problems on your operation.': 'Hay algunos problemas con su operación',
	Leave: 'Salir',
	Data: 'Datos',
	'Final steps': 'Finalización',
	'Card number': 'Numero de tarjeta',
	'Enter your credit card number': 'Ingrese el número de tarjeta',
	'Invalid credit card': 'Tarjeta no valida',
	'Card holder name': 'Nombre del titular de la tarjeta',
	'Enter the card holder name': 'Nombre del titular de la tarjeta',
	'Due date': 'Validez',
	"Enter the card's due date": 'Ingrese la validez de la tarjeta',
	'Invalid month': 'Mes inválido',
	'Invalid card date': 'Fecha de tarjeta inválida',
	'Enter the security code': 'Introduzca el código de seguridad',
	'Invalid code': 'Codigo invalido',

	// Maintenance
	"We are going through maintenance, we'll be back soon..": 'Estamos en mantenimiento, pronto estaremos de vuelta',

	// Finalization
	'Personal data': 'Datos personales',
	'Document no.': 'CPF',
	'You will only be charged after the free test period, it can be cancelled at anytime.':
		'El monto del cargo solo se realizará después del período gratuito y se puede cancelar en cualquier momento',
	Discounts: 'Descuentos',
	'I have read and agree with the': 'He leído y estoy de acuerdo con el',
	Close: 'Cerrar',

	// User Activation - Information
	'Validating coupon': 'Cupón de validación',
	"Couldn't find the coupon": 'No se puede obtener el cupón',
	Individual: 'Individual',
	Team: 'Equipo',
	Business: 'Negocio',
	'Enter your document no.': 'Introduzca el CPF',
	'Enter a valid document': 'Introduzca un CPF válido',
	'Enter a phone number': 'Introduzca un teléfono',
	'Enter the zip code': 'Ingrese el código postal',
	'Enter the city': 'Entra en la ciudad',
	'Enter the state': 'Introduzca el estado',
	'Street number': 'Número de calle',
	'Street number:': 'Número de calle:',

	// Credit Card
	'Under review': 'Tarjeta en revisión',
	'Insufficient funds': 'Fondos insuficientes',
	'Not accepted': 'Transacción inválida',
	'Customer already registered': 'Usuario ya registrado',
	'Failed to perform transaction': 'No se pudo realizar la transacción',

	'email must be unique': 'Correo electrónico ya registrado',

	'Error loading video': 'Error al cargar el video',
	'Failed to fetch your plan information.': 'No se pudo obtener la información de su plan',
	'Failed to fetch total customer videos.': 'No se pudo obtener el total de videos del cliente',
	'Maximum uploads reached.': 'Cargas máximas alcanzadas',
	'Maximum minutes reached.': 'Minutos máximos alcanzados',
	'Corrupted video.': 'Vídeo corrupto',

	// Painel control
	'Maximum users reached': 'Máximo de usuarios registrados',

	"The 'name' field is required.": 'El campo de nombre es obligatorio',
	"The 'email' field is required.": 'El campo de correo electrónico es obligatorio',
	"The 'country' field is required.": 'El campo del país es obligatorio',
	"The 'state' field is required.": 'El campo de estado es obligatorio',
	"The 'city' field is required.": 'El campo de la ciudad es obligatorio',
	"The 'address' field is required.": 'El campo de dirección es obligatorio',
	"The 'street_number' field is required.": 'El campo de número es obligatorio',
	"The 'zipcode' field is required.": 'El campo del código postal es obligatorio',

	// ********************************** //
	'Personal information': 'Informaciones personales',
	Localization: 'Ubicación',

	'Full name': 'Nombre completo',
	'Full name:': 'Nombre completo:',
	'Enter your full name': 'Ingrese su nombre completo',
	'Enter your name': 'Ingrese su nombre',
	'Insert a document': 'Insertar un documento',
	'Insert a valid document': 'Insertar un documento válido',
	Address: 'Dirección Sectorial',
	'Address:': 'Dirección Sectorial:',
	'Enter an address': 'Introduce una direccion',
	Number: 'Número',
	'Enter the number': 'Ingrese un numero',
	Zipcode: 'Código postal',
	'Zipcode:': 'Código postal:',
	'Enter zip code': 'Introduzca el código postal',
	Country: 'País',
	'Country:': 'País:',
	'Select your country': 'Selecciona tu pais',
	'Select state': 'Seleccione el estado',
	City: 'Ciudad',
	'City:': 'Ciudad:',
	'Select city': 'Seleccione la ciudad',

	'Update completed': 'Operación realizada',
	'Customer information updated successfully': 'Información actualizada con éxito',
	'Fill in your personal information': 'Complete su información personal',

	// Forgot password
	'Forgot your password?': '¿Olvidaste tu contraseña?',
	'We will send you an e-mail to regain access to your account, rest assured!':
		'Te enviaremos un e-mail para recuperar el acceso a su cuenta, ¡tenga la seguridad!',
	'Back to login': 'Atrás para iniciar sesión',
	'Please enter a valid e-mail address': 'Introduzca un correo electrónico válido',
	'Enter an e-mail': 'Introduzca un correo electrónico',

	'Oops... Something went wrong!': 'Ops.. Algo salió mal',
	'Operation performed successfully': 'Operación realizada con éxito',
	'Check your email inbox': 'Revisa tu bandeja de entrada de correo electrónico',
	'Create new password': 'Crear una nueva contraseña',
	'Your new password must be different from previous used passwords':
		'Su nueva contraseña debe ser diferente de las contraseñas que utilizó anteriormente',
	'Enter your new password': 'Introduzca su nueva contraseña',
	'Confirm new password': 'Confirme la nueva contraseña',
	'The two passwords that you entered do not match': 'Las dos contraseñas que ingresaste no coinciden',
	Save: 'Guardar',
	'Click here to continue': 'Clic aquí para continuar',
	'Invalid Token': 'Simbolo no valido',
	'Expired token': 'Contador caducado',

	trunk: 'Tronco',
	neck: 'Cuello',
	left_lower_arm: 'Codo izquierdo',
	right_lower_arm: 'Codo derecho',
	left_upper_arm: 'Hombro izquierdo',
	right_upper_arm: 'Hombro derecho',
	left_knee: 'Rodilla izquierda',
	right_knee: 'Rodilla derecha',
	left_ankle: 'Tobillo izquierdo',
	right_ankle: 'Tobillo derecho',
	hip: 'Cadera',
	left_hand: 'Puño izquierdo',
	right_hand: 'Puño derecho',

	Knee: 'Rodilla',
	Ankle: 'Tobillo',
	Hip: 'Cadera',
	Hand: 'Muñecas',
	Qualification: 'Calificación',
	'Select the body parts to display': 'Seleccione las partes del cuerpo que se mostrarán',

	safe: 'Aceptable',
	info: 'Ligero',
	warning: 'Alto',
	danger: 'Extremo',

	'Light risk': 'Riesgo ligero',
	'Average risk': 'Riesgo medio',

	Level: 'Nivel',
	'Limit of bands reached': 'Límites de rango de banda alcanzados',

	'Industrial site': 'Centro de trabajo',
	Period: 'Período',
	Search: 'Buscar',
	Clear: 'Borrar',

	'Select company': 'Seleccione empresa',

	//User management
	'Create new user': 'Crear nuevo usuario',
	Email: 'Correo electrónico',
	Permissions: 'Permisos',
	'Companies - Industrial sites': 'Empresas - Centro de trabajo',

	'Change password': 'Cambiar contraseña',
	'Current password': 'Contraseña actual',
	'New password': 'Nueva contraseña',
	'Confirm password': 'Confirmar seña',
	'Enter your current password': 'Introduce tu contraseña actual',
	'Enter a new password': 'Introduzca una nueva contraseña',
	'Password must be at least 6 characters long': 'La contraseña debe tener al menos 6 caracteres',
	'Repeat your new password': 'Repita su nueva contraseña',
	"The two passwords don't match, check": 'Las dos contraseñas no coinciden, verifique',
	'Sorry time exceeded, try again.': 'Lo sentimos, se excedió el tiempo, inténtelo de nuevo',
	'Invalid current password': 'Contraseña actual inválida',
	'Update information': 'Actualice la información',

	'Phone number': 'Teléfono',
	'Account type': 'Tipo de cuenta',
	'Created in': 'Creado en',
	'Postal code': 'Código postal',
	'Sorry, something went wrong :(': 'Perdón, algo salió mal :(',
	Retry: 'Intentar nuevamente',

	//
	// Layout - Sidebar
	Settings: 'Ajustes',
	'My account': 'Mi cuenta',
	Plan: 'Plan',
	'Subscription type': 'Tipo de suscripción',
	'Subscription type:': 'Tipo de suscripción:',
	Recurrence: 'Reaparición',
	'Recurrence:': 'Reaparición:',
	'Expiration date': 'Fecha de expiración',
	'Plan expiration date:': 'Fecha de vencimiento del plan:',
	'Active users': 'Usuarios activos',
	'Processed videos': 'Vídeos procesados',
	'Processed videos:': 'Vídeos processados:',
	'Processing time': 'Tiempo de procesamiento',
	'Processing time:': 'Tiempo de procesamiento:',
	'Additional minutes': 'Minutos adicionales',
	'Add credits': 'Agregar créditos',
	'Renew subscription': 'Renovar la suscripción',
	'Change subscription': 'Cambiar firma',
	'Cancel subscription': 'Cancelar suscripción',
	of: 'en',
	minutes: 'minutos',

	Test: 'Prueba',
	Minutes: 'Minutos',
	'Consolidate data': 'Consolidar datos',
	'Export report': 'Reporte de exportacion',
	'There is no post with screwdriver / screwdriver': 'No hay poste con destornillador/destornillador',
	'Data not found': 'Datos no encontrados',
	'Requires actions': 'Requiere acciones',
	'No actions required': 'No requiere acciones',
	'High Risk and Requires Action': 'Alto riesgo y requiere acciones',
	'Moderate risk and no action required': 'Riesgo moderado y no requiere acción',
	'Low Risk and No Action Required': 'Bajo riesgo y no requiere acción',
	'Intensity of work': 'Intensidad del trabajo',
	'Work organization': 'Organización del trabajo',
	'Is spontaneous breaks allowed during the workday?':
		'¿Están permitidos los descansos espontáneos durante la jornada laboral?',
	'Is there a diversification of tasks in the performance of your functions?':
		'¿Existe diversificación de tareas en el desempeño de sus funciones?',
	'Materials are always available at the workstation, avoiding rush, delay or overload?':
		'¿Los materiales están siempre disponibles en el puesto de trabajo, evitando prisas, retrasos o sobrecargas?',
	Complexity: 'Complejidad',
	'Movements do not require high dexterity': 'Los movimientos no requieren altas habilidades',
	'Low level of attention requirement for long periods':
		'Bajo nivel de exigencia de atención durante largos períodos',
	'Workstation is complex and high cognitive demand': 'El rango es complejo y cognitivamente exigente',
	'Workstation is simple and of low cognitive requirement': 'Rank es simple y de baja demanda cognitiva',
	'Displacement ': 'Desplazamiento ',
	'Squat ': 'Ponerse en cuclillas ',
	Cervical: 'Cervical',
	//
	'Left Shoulder/Arm': 'Hombro/brazo izquierdo',
	'Right Shoulder/Arm': 'Hombro/brazo derecho',
	'Left wrist': 'Puño izquierdo',
	'Right wrist': 'Puño derecho',
	'No repeatability': 'Sin repetibilidad',
	'With repetition': 'Con repetibilidad',
	' x per hour': ' x por hora',
	'Without risk': 'Sin riesgo',
	'Action Level': 'Nivel de acción',
	'Complementary resources': 'Factores complementarios',
	'Percussion tools ': 'Herramientas de percusión',
	'Hit against ': 'Golpes en contra',
	'Present Risk': 'Riesgo presente',
	'Low risk': 'Riesgo bajo',
	'Global Ranking': 'Clasificación mundial',
	'Operator report': 'Reportes del operador',
	'Enter operator report below': 'Ingrese el informe del operador a continuación',
	'Easy to understand information': 'Información fácil de entender',

	'≤ 10 steps per minute': '≤ 10 pasos por minuto',
	'15 to 25 steps per minute': 'De 15 a 25 pasos por minuto',
	'Above 25 steps per minute': 'Por encima de 25 pasos por minuto',

	'Inform below the displacement of the operator during the workday':
		'Informar a continuación el desplazamiento del operador durante la jornada laboral',
	'Regulation notes': 'Notas reglamentarias',
	'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)':
		'Se permiten descansos espontáneos durante la jornada laboral (por ejemplo, beber agua, beber café, ir al baño)',
	'In the performance of their functions, there is diversification of tasks':
		'En el desempeño de sus funciones, existe diversificación de tareas',
	'Materials are always available at the workstation, avoiding rush, delay or overload':
		'Los materiales están siempre disponibles en el puesto de trabajo, evitando prisas, demoras o sobrecargas',
	'Work intensity: give a score from 1 to 7 (1 being a little work and 7 being very intense work)':
		'Intensidad del trabajo: puntúe del 1 al 7 (siendo 1 un poco de trabajo y 7 un trabajo muy intenso)',

	Wrist: 'Muñecas',
	'Hit against': 'Golpes',
	'Enter job information below': 'Ingrese la información del trabajo a continuación',
	'During the operation, the operator taps his hands to adjust parts or components.':
		'Durante la operación, el operador da golpes con las manos para ajustar piezas o componentes',

	'Add a complementary video for wrist analysis': 'Agregue un video complementario para el análisis de la muñeca',
	'Do not include in the analysis': 'No incluir en el análisis',
	'Include external video': 'Incluir video externo',
	'Enter video name': 'Introduce el nombre del vídeo',

	'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)':
		'El mazo y/o los mazos son golpes de golpes duros (excepto ajustes de golpe)',
	'Light - Quiet': 'Ligero - Silencioso',
	'Medium - Some effort is perceived (defined)': 'Medio - Se percibe algún esfuerzo (definido)',
	'Heavy - Clear effort: no change in facial expression':
		'Pesado - Esfuerzo claro: sin cambios en la expresión facial',
	'Very heavy - Sharp effort: changes facial expression': 'Muy pesado Esfuerzo claro: cambia la expresión facial',
	'Next. maximum - Use of torso and limbs to generate greater force':
		'Próximo. máximo - Uso del tronco y las extremidades para generar mayor fuerza',

	// ------- //
	'Workstation Information': 'Informacion del trabajo',

	'Enter operator difficulty control below': 'Ingrese el control de dificultad del operador a continuación',
	'Control Difficulty': 'Dificultad de control',
	'Select a cost center': 'Seleccione el centro de costo',

	'Enter data about vibration in employee activity below':
		'Ingrese a continuación los datos sobre la vibración en la actividad del empleado, si no los tiene, no es necesario llenarlos',

	"Enter below the data on the employee's reaction in the activity":
		'Ingrese a continuación los datos sobre la reacción del empleado a la actividad',
	'Insert a new reaction': 'Introduzca una nueva reacción',
	'Insert a new vibration': 'Entra en una nueva vibración',

	"Enter below the data of the employee's effort in the activity":
		'Ingrese a continuación los datos sobre el esfuerzo del empleado en la actividad',

	'Insert a new effort': 'Introduzca un nuevo esfuerzo',
	'Cost center': 'Introduzca el trabajo',
	'Enter a workstation': 'Introduzca el trabajo',

	'Enter the data related to the selected video below':
		'Ingrese los datos relacionados con el video seleccionado a continuación',

	Staff: 'Staff de colaboradores',
	'Enter the Company Activities': 'Entra en el Codigo Fiscal',
	'Insert a production line': 'Ingrese a la línea de producción',
	'Add new range': 'Agregar nueva banda',
	'industrial site': 'centro de trabajo',
	'Production line': 'Línea de producción',
	'Select a production line': 'Seleccione la línea de producción',

	Role: 'Cargo',
	Code: 'Código',

	//
	'Tax Code': 'RUT',
	'Enter a Tax Number': 'Ingresa el RUT',
	'Insert a valid Tax Number': 'Introduzca un RUT válido',

	'Select a role': 'Seleccione un tipo',
	'User type': 'Tipo de usuario',
	'User type:': 'Tipo de usuario:',
	Document: 'Documento',
	'Document:': 'Documento:',
	'Current plan': 'Plan actual',
	'Not provided': 'No informado',

	'Risk ranges': 'Bandas de riesgo',
	'Local application': 'Sitio de aplicación',
	'Units for analysis': 'Unidades a analizar',
	'Select the units for review': 'Seleccione las unidades que se incluirán en la revisión',
	Factory: 'Fábrica',
	'Select the industrial sites for analysis': 'Seleccionar las plantas industriales a analizar',
	'Production Volume': 'Volumen de producción',
	Investments: 'Inversiones',
	'Enter production volumes below': 'Ingrese los volúmenes de producción a continuación',
	'Inform below the investments in ergonomics': 'Informar a continuación las inversiones en ergonomía',

	Date: 'Fecha',
	'Date of reference': 'Fecha de referencia',
	'Date of the report': 'Fecha del informe',

	'Ergonomic analysis of work': 'Análisis ergonómico del trabajo',
	Presentation: 'Presentación',
	'Plant Information': 'Información de la planta',
	'Insert a company name': 'Introduzca el nombre de la empresa',
	'Risk degree': 'Grado de riesgo',
	Activity: 'Actividad',
	District: 'Barrio',
	Administrative: 'Administrativo',
	'Work Shift': 'Turno de trabajo',
	Operational: 'Operacional',
	'Direct hourly worker': 'Directo por hora',
	'Indirect hourly worker': 'Indirecto por hora',
	'Monthly worker': 'Pagador mensual',
	'Normal working hours': 'Horas normales de trabajo',

	// AET
	'Include checklists related to this analysis below':
		'Incluya listas de verificación relacionadas con este análisis a continuación',
	'Non-standard scale': 'Escala no estándar',

	// Bodyparts
	'Lower members': 'Miembros inferiores',
	'Left elbow': 'Codo izquierdo',
	'Right elbow': 'Codo derecho',
	'Left shoulder': 'Hombro izquierdo',
	'Right shoulder': 'Hombro derecho',
	'Left knee': 'Rodilla izquierda',
	'Right knee': 'Rodilla derecha',
	'Left ankle': 'Tobillo izquierdo',
	'Right ankle': 'Tobillo derecho',
	'Left grips': 'Puños izquierdos',
	'Right grips': 'Puños derechos',
	'Left forearm': 'Antebrazo izquierdo',
	'Right forearm': 'Antebrazo derecho',
	'Right fingers': 'Dedos derechos',
	'Left fingers': 'Dedos izquierdos',

	/* Nomenclature v2 */
	'Left hand': 'Mano izquierda',
	'Right hand': 'Mano derecha',
	'Video description': 'Descripción del video',
	Below: 'Abajo de',
	Above: 'Encima de',

	Checklist: 'Lista de verificación',
	'Frequency and duration': 'Frecuencia y duración',

	'Date of collection': 'Fecha de recolección',

	'Load from 2 kg ≤ 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg ≤ 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estática o repetitiva)',
	'Load more than 10 kg of repetitive load or rapid build up of force':
		'Carga superior 10 kg de carga repetitiva o golpes',

	Medium: 'Medio',
	Invalid: 'Inválido',

	Safe: 'Aceptable',
	Info: 'Ligero',
	Danger: 'Extremo',

	'No corrective action needed': 'No se requiere acción correctiva',
	'Corrective action as soon as possible': 'Acción correctiva tan pronto como sea posible',
	'High-priority corrective action': 'Acción correctiva de alta prioridad',
	'Immediate corrective action': 'Acción correctiva inmediata',
	'Unable to show results': 'No se pueden mostrar los resultados',

	/* Nomenclature */
	Line: 'Línea',
	Effort: 'Esfuerzo',
	Reaction: 'Reacción',
	Vibration: 'Vibración',
	'Beats against': 'Golpes en contra',
	Percussion: 'Percusión',
	Hands: 'Manos',
	'Shoulders/Arms': 'Hombros/brazos',
	Left: 'Izquierda',
	Right: 'Derecha',
	Exit: 'Salir',
	Filters: 'Filtros',

	/* Login - Page */
	// Placeholder's
	'E-mail': 'Correo electrónico',
	'E-mail:': 'Correo electrónico:',

	// Validation's errors inputs
	'Enter a valid e-mail': 'Introduzca un correo electrónico válido',
	'Enter your password': 'Ingresa tu contraseña',
	Login: 'Acceso',

	// SideMenu
	Dashboard: 'Tablero',
	Upload: 'Subir',
	EAW: 'AET',

	// Dashboard
	'Comparison over the last 6 months': 'Comparación en los últimos 6 meses',
	/*************/

	'Are you sure?': '¿Está seguro?',
	'Are you sure you want to quit? You have an upload in process.':
		'¿Seguro que quieres salir? Tienes una carga en proceso',

	// Upload filter
	'Select a company': 'Seleccione una empresa',
	'Select an industrial site': 'Seleccione un centro de trabajo',
	'Select a line': 'Seleccione la línea',
	'Select the workstation': 'Seleccione el puesto de trabajo',

	// Chart
	'No data available': 'No hay datos',
	'There is no registered data.': 'No hay datos registrados',

	// Layout
	Logout: 'Salir',

	// Report
	"Approximated values, depends mainly on the filming methods. In case there is no data, the algorithm didn't find the points to determine the angle, please review the filming method used.":
		'Valores aproximados, en función de la técnica de filmación adoptada. Si no tiene datos, el algoritmo no ha identificado los puntos para determinar el ángulo, observe las técnicas de filmación',
	'The results obtained from each body part can be affected by the method and position of filming, the identification of the person in the analysis can suffer interference from objects present in the room. The angle data shown are approximate values.':
		'Los resultados obtenidos de cada parte del cuerpo pueden verse afectados por el método y la posición de filmación, la identificación de la persona en el análisis puede sufrir interferencias de los objetos presentes. Los datos de ángulo que se muestran son valores aproximados.',
	Reference: 'Referencia',
	'Investigate and change immediately': 'Investigue y cambie inmediatamente',
	'Analysis of the movement by angle': 'Análisis de movimiento por ángulo',
	'Analysis of the movement by score': 'Análisis de movimiento con puntuación',
	'Analysis of the movement & Body parts': 'Análisis de movimiento y partes del cuerpo',
	'Report types': 'Tipos de Reportes',
	'Generate reports': 'Generar Reportes',
	Finish: 'Finalizar',
	'Description already exists!': '¡La descripción ya existe!',
	'Enter a new sector': 'Entrar en un nuevo sector',
	'Do you wish to delete this sector?': '¿Quieres eliminar este sector?',
	'This sector is used in other reports, do you really wish to delete it?':
		'Este sector se está utilizando en otros Reportes, ¿está seguro de que desea eliminarlo?',
	'Sector created successfully!': '¡Sector creado con éxito!',
	"This operation couldn't be completed": 'Esta acción no se pudo realizar',
	Sectors: 'Sectores',
	'Fill in the essential information below.': 'Complete la información esencial a continuación',
	'Select the sector from the above analysis': 'Seleccione el sector del análisis anterior',
	'Select the sector for the analysis': 'Seleccione el sector de análisis',
	Add: 'Agregar',
	'Enter the workstation': 'Introduzca el trabajo',
	'Enter the date the collection was made': 'Ingrese la fecha en que se realizó el cobro',
	'Select the tool for the analysis and the type of report you wish to generate.':
		'Seleccione la herramienta que desea aplicar para el análisis y el tipo de informe que desea generar',
	Tool: 'Herramienta',
	'Pick the tool above': 'Seleccione la herramienta de arriba',
	'Select the type of tool': 'Seleccione el tipo de herramienta',
	Report: 'Reporte',
	'Select the type of report above': 'Seleccione el tipo de reporte anterior',
	'Select the type of report': 'Seleccione el tipo de reporte',
	Squatting: 'En cuclillas',
	Video: 'Video',
	'Not identified': 'No identificado',
	'Very high': 'Muy alto',
	Comments: 'Comentarios',
	'Comments:': 'Comentarios:',
	Filter: 'Filtrar',
	Company: 'Empresa',
	'Select the company': 'Seleccione la empresa',
	'Select the industrial site': 'Seleccione el centro de trabajo',
	Function: 'Ocupación',
	'Enter the function': 'Introduzca la función',
	'Customized reports': 'Reportes personalizados',
	'Manual Tools': 'Herramientas manuales',
	'List of work organization elements': 'Lista de elementos de organización del trabajo',
	'Work organization factor': 'Factor organización del trabajo',
	'Tasks at the pace of the machine': 'Tareas al ritmo de la maquina',
	'Incentives are charged': 'Se cobran incentivos',
	'Exists as routine, overtime': 'Existe como rutina, horas extras',
	'Strict control of working times': 'Control estricto de los tiempos de trabajo',
	'Limited decision-making possibilities': 'Escasa posibilidad de toma de decisiones',
	'Monotonous work': 'Trabajo monótono',
	Shoulder: 'Hombro',
	'Individually evaluate the upper extremities': 'Evaluar individualmente las extremidades superiores',
	'Flexion/extension': 'Flexión/extensión',
	'Angle in time': 'Ángulo en el tiempo',
	'Extreme posture': 'Postura extrema',
	Speed: 'Velocidad',
	'Total duration of the activity': 'Duración total de la actividad',
	Strength: 'Fortaleza',
	Abduction: 'Abducción',
	Forearm: 'Antebrazo',
	'Pronation, supination and rotation': 'Pronación, supinación y rotación',
	Slow: 'Lento',
	Static: 'Estático',
	Fast: 'Rápido',
	'Supports part of the weight': 'Soporta parte del peso',
	'Apply force': 'Aplicar fuerza',
	"Don't apply force": 'No apliques fuerza',
	Partial: 'Parcial',
	Neutral: 'Neutral',
	Complete: 'Completo',
	'Partial + straight fingers': 'Parcial + dedos rectos',
	'Partial + flexed fingers': 'Parcial + dedos flexionados',
	Grips: 'Agarres',
	'Flexion/extension or radial/ulnar deviation': 'Flexión/extensión o desviación radial/cubital',
	'Tool pressure': 'Presión herramienta',
	Circular: 'Circular',
	Pinch: 'Pellizco',
	Dynamic: 'Dinámico',
	Infrequent: 'Infrecuente',
	'On palm or wrist': 'En la palma o la muñeca',
	Fingers: 'Dedos',
	Keystrokes: 'Pulsaciones',
	'Two hands': 'Dos manos',
	'Flexion, extension, rotation': 'Flexión, extensión, rotación',
	'Risk Condition': 'Condición de riesgo',
	'Risk condition': 'Condición de riesgo',
	'Identify problems associated with tool use and exposure to vibration.':
		'Identificar los problemas asociados al uso de herramientas y a la exposición a las vibraciones.',
	'Vibration (Tool is pneumatic)': 'Vibración (Herramienta es neumática)',
	'Uses tools that produce vibration': 'Usa herramientas que producen vibración ',
	'Total daily exposure': 'Exposición diaria total',
	'Exhaustion (Directly in hand)': 'Exhaustación (Directamente en mano)',
	'Ambient temperature': 'Temperatura ambiente',
	'Measurement (m/s²)': 'Medición (m/s²)',
	'Vibration (Electric motor or combustion engine)': 'Vibración (motor eléctrico o motor de combustión)',

	'Action Plan': 'Plan de acción',
	'Action plans created': 'Planes de acción creados',
	'Task description': 'Descripcion de la tarea',
	'Number of exhibits': 'Número de expuestos',
	'Classification of risks': 'Clasificación de los riesgos',
	'Grip type': 'Tipo de agarre',
	'Trigger action': 'Pulsaciones',
	'If the tool is electric motor or combustion engine': 'Si la herramienta es motor eléctrico o de explosión',
	'If the tool is pneumatic': 'Si la herramienta es neumática',
	'Part of the body': 'Parte del corpo',
	Movement: 'Movimiento',
	shoulder: 'Hombro',
	wrist: 'muñeca',
	forearm: 'antebrazo',
	grips: 'agarre',
	fingers: 'dedos',
	vibration: 'vibración',
	'Date of analysis': 'Fecha del análisis',
	'Flexion < 20°': 'Flexión < 20°',
	'Rotation > 45°': 'Rotación > 45°',
	'Flexion > 20°': 'Flexión > 20°',
	'Flexion > 20° and Rotation > 45°': 'Flexión > 20° e Rotación > 45°',

	'* ANSI Z-365 Control of Work-Related Cumulative Trauma Disorder, Part 1: Upper Extremities, Working Draft, National safety Council-Secretariat, January 1, 1996.':
		'* ANSI Z-365 Control del trastorno por traumatismo acumulativo relacionado con el trabajo, Parte 1: Extremidades superiores, Borrador de trabajo, Consejo-Secretaría Nacional de Seguridad, 1 de enero de 1996.',
	'Select the': 'Seleccione',
	'Input the': 'Introduzca',
	right: 'derecho',
	left: 'izquierdo',
	'Action Plans': 'Planes de Acción',
	'More serious risks': 'Riesgos más graves',
	'Detailed risk classification': 'Clasificación detallada de los riesgos',
	Signature: 'Assinatura',
	'Evaluated item': 'Artículo evaluado',

	// Settings
	"We weren't able to change your password": 'No se puede cambiar su contraseña',
	'Warning!': '¡Alto!',
	'Failed to fetch requested information': 'No se pudo obtener la información solicitada',
	'Do you really wish to cancel your plan?': '¿De verdad quieres cancelar tu plan?',
	'After cancelling your plan, when the expiration date comes, you will have no more access to the platform.':
		'Al cancelar tu plan, después de la fecha de vencimiento actual no tendrás acceso a la plataforma',
	"We weren't able to cancel your plan": 'No se puede cancelar el plan',
	"Select a plan that's different from your current one": 'Seleccione un plan diferente a su plan actual',
	"We couldn't process the operation": 'No se puede realizar la operación',
	'Plan cancelled on:': 'Plan cancelado en:',
	'Active users:': 'Usuarios activos:',
	'Plan switch': 'Cambio de planes',
	'You have recently changed your plan': 'Recientemente cambió su suscripción',
	'Plan type:': 'Tipo de suscripción:',
	'New plan start date:': 'Fecha de inicio del nuevo plan:',
	'User limit:': 'Límite de usuarios:',
	'Upload limit:': 'Límite de carga:',
	'Processing time limit:': 'Límite de minutos de procesamiento:',
	'Cancel plan': 'Cancelar plan',
	'Error when trying to verify industrial sites from company.':
		'Error al verificar plantas industriales dentro de la empresa',
	'Error when trying to delete the company': 'Error al eliminar empresa',
	'Enter a permission': 'Introduzca un permiso',
	'Select a permission': 'Seleccione un permiso',
	'Edit user': 'Editar usuario',
	'User changed successfully': 'Usuario cambiado con éxito',
	Permission: 'Permiso',
	'Do you wish to delete this user?': '¿Quieres eliminar este usuario?',
	Active: 'Activo',
	Disabled: 'Desactivado',

	// Manage companies
	'Manage companies': 'Administrar empresas',
	'Manage industrial sites': 'Administrar plantas industriales',
	'Heads up! This company is not empty': '¡Alto! Esta empresa no está vacía',
	'To continue, first remove industrial sites belonging to this company.':
		'Para continuar, eliminar las plantas industriales pertenecientes a esta empresa',
	'All industrial sites in this company will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Se eliminarán todas las plantas industriales de esta empresa, junto con sus cargas e Reportes. ¿Estas seguro que deseas continuar?',
	'All uploads and reports from this industrial site will be deleted. This action cannot be undone. Do you wish to continue?':
		'Se eliminarán todas las cargas e Reportes de este centro de trabajo. Esta acción no se puede deshacer. ¿Estas seguro que deseas continuar?',
	'Failed to update information': 'No se pudo actualizar la información',
	'Edit company': 'Editar empresa',
	Loading: 'Cargando',
	'Loading...': 'Cargando...',
	'Company name': 'Nombre de la empresa',
	'Corporate name': 'Razón Social',
	'Industrial sites': 'Centros de trabajo',
	'Updated information': 'Información actualizada',
	'Error while changing the name of the industrial site': 'Error al editar el nombre del centro de trabajo',
	Inactive: 'Inactivo',
	All: 'Todo',
	'Organization created successfully': 'La organización se creó correctamente',
	'Organization updated successfully': 'La organización se actualizó correctamente',
	'Organization deleted successfully': 'La organización se eliminó correctamente',
	'Data created successfully': 'Los datos se crearon correctamente',
	'Data updated successfully': 'Los datos se actualizaron correctamente',
	'Data deleted successfully': 'Los datos se eliminaron correctamente',
	'Please contact support to reactivate it': 'Por favor, ponte en contacto con soporte para activarlo nuevamente',
	'Create a new workstation': 'Crear un nuevo puesto de trabajo',
	'Create a new sector': 'Crear un nuevo sector',

	// ui/Reports
	'1 - Acceptable posture if not repeated or kept for long periods':
		'1 - Postura aceptable si no se repite o mantiene durante largos periodos',
	'2 - Investigate; possibility to request for change; it is convenient to introduce alterations':
		'2 - Investigar posibilidad de solicitar cambios es conveniente introducir cambios',
	'3 - Investigate; make changes quickly': '3 - Investigar hacer cambios rápidamente',
	'4 - Immediate change': '4 - Cambios inmediatos',
	'Acceptable posture if not repeated or kept for long periods':
		'Postura aceptable si no se repite o se mantiene durante períodos prolongados',
	'Investigate; possibility to request for change; it is convenient to introduce alterations':
		'Investigar posibilidad de solicitar cambios es conveniente introducir cambios',
	'Investigate; make changes quickly': 'Investigar hacer cambios rápidamente',
	'Immediate change': 'Cambios inmediatos',
	Ergonomist: 'Ergonomista',
	'Left lower arm': 'Antebrazo izquierdo',
	'Right lower arm': 'Antebrazo derecho',
	'Left upper arm': 'Brazo izquierdo',
	'Right upper arm': 'Brazo derecho',
	Type: 'Escribe',

	// views/Report/Niosh
	'Enter the job characteristics below.': 'Ingrese las características del trabajo.',
	'Gender of the worker': 'Género del trabajador',
	'Select the gender': 'Seleccione el género',
	'Select the age': 'Seleccione la edad',
	Age: 'Años',
	'How many workers are lifting the object?': '¿Cuántos trabajadores están levantando el objeto?',
	'Which hands are used?': '¿Qué manos se utilizan?',
	One: 'Uno',
	Two: 'Dos',
	Three: 'Tres',
	'One hand': 'Una mano',
	'Both hands': 'Ambas manos',
	'Manual Lifting Report': 'Informe - Levantamiento Manual',
	Conclusions: 'Conclusiones',
	'The weight to be lifted': 'El peso a levantar',
	'is greater than the recommended weight limit (RWL)': 'es mayor que el límite de masa recomendado (RML)',
	'is less than the recommended weight limit (RWL)': 'es inferior que el límite de masa recomendado (RML)',
	' The lifting index (LI)': ' El índice de levantamiento (IL)',
	'is more than 3.': 'es más de 3.',
	'is more than 2 and less than 3.': 'esta entre 2 y 3.',
	'is more than 1,5 and less than 2.': 'esta entre 1,5 y 2.',
	'is more than 1 and less than 1,5.': 'esta entre 1 y 1,5.',
	'is less than 1.': 'es menor que 1.',
	' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' Hay un riesgo muy alto. Estos valores indican que este levantamiento sería peligroso para la mayoría de los trabajadores industriales sanos.',
	' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' Hay un alto riesgo. Estos valores indican que este levantamiento sería peligroso para la mayoría de los trabajadores industriales sanos.',
	' There is a moderate risk. These values indicate that this job is somewhat stressful.':
		' Hay un riesgo moderado. Estos valores indican que este trabajo es ligeramente estresante.',
	' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		' Hay un riesgo bajo. Estos valores indican que este trabajo es adecuado para la mayoría de los trabajadores industriales, pero algunos pueden tener problemas.',
	' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.':
		' Hay un riesgo muy bajo. Estos valores indican que este trabajo es adecuado para la mayoría de los trabajadores industriales.',
	Man: 'Hombre',
	Woman: 'Mujer',
	'20 to 45 years old': '20 a 45 años',
	'< 20 years old': '< 20 años',
	'> 45 years old': '> 45 años',
	'Download PDF': 'Descargar PDF',
	'The most critical variables are listed below with suggestions:':
		'Las variables más críticas se enumeran a continuación con sugerencias:',
	'Bring the load closer to the worker by removing any horizontal barriers or reducing the size of the object. Lifts near the floor should be avoided.':
		'Acerque la carga al trabajador eliminando cualquier barrera horizontal o reduciendo el tamaño del objeto. Deben evitarse levantamientos cerca del suelo.',
	'Raise/lower the origin/destination of the lift. Avoid lifting near the floor or above the shoulders.':
		'Eleve el origen o baje el objetivo de elevación. Evite levantamientos cerca del piso o por encima de los hombros.',
	'Reduce the vertical distance between the origin and the destination of the lift.':
		'Reducir la distancia vertical entre el origen y el destino del levatamiento.',
	'Move the origin and destination closer together to reduce the angle of twist, or move them further apart to force the worker to turn the feet and step, rather than twist the body.':
		'Acerque el origen y el destino para reducir el ángulo de giro, o sepárelos más para obligar al trabajador a girar los pies y dar un paso, en lugar de torcer el cuerpo.',
	'Reduce the lifting frequency rate, reduce the lifting duration, or provide longer recovery periods (i.e., light work period).':
		'Reduzca la tasa de frecuencia de levantamiento, reduzca la duración del levantamiento o proporcione períodos de recuperación más prolongados (período de trabajo liviano).',
	'Improve the hand-to-object coupling by providing optimal containers with handles or handhold cutouts, or improve the handholds for irregular objects.':
		'Mejore el acoplamiento mano-objeto proporcionando recipientes óptimos con asas o recortes para asideros, o mejore los asideros para objetos irregulares.',
	'Consider using both hands when lifting the weight.': 'Considere usar ambas manos al levantar el peso.',
	'One handed': 'Con una sola mano',
	'Distance H': 'Distancia H',
	'Distance Vc': 'Distancia Vc',
	'Distance Dc': 'Distancia Dc',
	'Angle A': 'Ángulo A',
	'Frequency/Duration': 'Frecuencia/Duración',
	Coupling: 'Agarre',
	Frequency: 'Frecuencia',
	Conclusion: 'Conclusión',
	'Coupling factor': 'Calidad de agarre',
	'Frequency factor': 'Factor de frecuencia',
	Poor: 'Malo',
	Fair: 'Regular',
	Good: 'Bueno',
	'1 hour': '1 hora',
	'1 - 2 hours': '1 a 2 horas',
	'2 - 8 hours': '2 a 8 horas',
	'Weight of the object': 'Peso del objeto',
	'Distance the object is moved vertically': 'Distancia a la que se mueve el objeto verticalmente',
	'Vertical distance of the object at the beginning of handling, relative to the ground':
		'Distancia vertical del objeto al inicio de la manipulación, con respecto al suelo',
	'Horizontal distance of the object relative to the body': 'Distancia horizontal del objeto con respecto al cuerpo.',
	'Rotation angle': 'Ángulo de rotación',
	'Recommended Weight Limit (RWL)': 'Límite de masa recomendado (LPR)',
	'Lifting Index (LI)': 'Índice de levantamiento (IL)',
	'lifts / min': 'levant. / min',
	'Report date': 'Fecha del informe',
	'Enter the required values': 'Ingrese los valores requeridos',
	'Comment:': 'Comentarios:',
	Details: 'Detalles',
	Risk: 'Riesgo',
	'Very low risk': 'Riesgo muy bajo',
	Measurements: 'Medidas',
	'Lifted by': 'Levantado por',
	'Lifted by ': 'Levantado por ',
	'1 worker': '1 trabajador',
	'2 workers': '2 trabajadores',
	'3 workers': '3 trabajadores',
	Using: 'Usando',
	'only one hand': 'una sola mano',
	'one hand': 'una mano',
	'both hands': 'ambas manos',
	'Reference weight': 'Peso de referencia',
	'Select the coupling quality': 'Seleccione la calidad de agarre',
	'Select the lifting frequency': 'Seleccione la frecuencia de levantamiento',
	'Select the lifting duration': 'Seleccione la duración de levantamiento',
	'Lifting frequency (lifts per minute)': 'Frecuencia de levantamiento (levant./min)',
	'Enter the task variables below.': 'Introduzca las variables de la tarea a continuación.',
	'Please enter a value': 'Introduzca un valor',
	'Coupling factor: ': 'Factor de agarre: ',
	'Frequency factor: ': 'Factor frecuencia: ',
	Characteristics: 'Características',
	Variables: 'Variables',
	Result: 'Resultado',
	'Report created successfully': 'Informe creado con éxito',
	'Report updated successfully': 'Informe actualizado correctamente',
	'Want to delete this report?': '¿Desea eliminar este informe?',
	'Report deleted successfully': 'Informe eliminado correctamente',
	'Comment updated successfully': 'Comentario actualizado con éxito',
	'Document created successfully': 'Documento creado con éxito',

	// STRAIN INDEX REPORT
	'Report Results - Revised Strain Index': 'Reporte de resultados - Strain Index Revisado',
	'Intensity of exertion': 'Intensidad de esfuerzo',
	'Efforts per minute': 'Esfuerzos por minuto',
	'Number of exertions': 'Número de esfuerzos',
	'Observation time': 'Tiempo de observación',
	'Duration per exertion': 'Duración por esfuerzo',
	'Hand/wrist posture': 'Postura de mano/muñeca',
	'Type of wrist posture': 'Tipo de postura de la muñeca',
	'Hand/wrist angle': 'Ángulo de mano/muñeca',
	'Duration of task per day': 'Duración de la tarea por día',
	'Revised Strain Index (RSI)': 'Strain Index Revisado (RSI)',
	'Risk factor': 'Factor de riesgo',
	Input: 'Entrada',
	Score: 'Score',
	'Job is probably safe': 'El trabajo es probablemente seguro',
	'Job is probably hazardous': 'El trabajo es probablemente peligroso',
	Degrees: 'Grados',
	'Duration/day': 'Duración/día',
	'Intensity of exertion (Borg Scale - BS)': 'Intensidad de esfuerzo (Borg Scale - BS)',
	Light: 'Ligero',
	'Somewhat Hard': 'Poco Difícil',
	Hard: 'Difícil',
	'Very Hard': 'Muy Difícil',
	'Near Maximal': 'Casi Maxima',
	'Risk factors 1': 'Factores de riesgo 1',
	'Risk factors 2': 'Factores de riesgo 2',
	'Barely noticeable or relaxed effort (BS: 0-2)': 'Esfuerzo casi imperceptible o relajado (BS: 0-2)',
	'Noticeable or definite effort (BS: 3)': 'Esfuerzo perceptible o definido (BS: 3)',
	'Obvious effort; Unchanged facial expression (BS: 4-5)': 'Esfuerzo obvio; Expresión facial sin cambios (BS: 4-5)',
	'Substantial effort; Changes expression (BS: 6-7)': 'Esfuerzo sustancial; Cambio de expresión (BS: 6-7)',
	'Uses shoulder or trunk for force (BS: 8-10)': 'Usa el hombro o el tronco para la fuerza (BS: 8-10)',
	'Number of exertions observed': 'Número de esfuerzos observados',
	'Total observation time': 'Tiempo total de observación',
	'Average single exertion time': 'Tiempo medio de esfuerzo',
	'(total exertions * single exertion time) must be less than or equal to the total observation time':
		'(esfuerzos totales * tiempo de esfuerzo) debe ser inferior o igual al tiempo total de observación',
	seconds: 'segundos',
	hours: 'horas',
	Extension: 'Extensión',
	Flexion: 'Flexión',
	'Revised Strain Index (Moore and Garg)': 'Strain Index Revisado (Moore y Garg)',
	'Report Results': 'Reporte de resultados',
	'Revised Strain Index': 'Strain Index Revisado',
	'(Moore and Garg)': '(Moore y Garg)',

	// REBA
	'REBA Report Results': 'Reporte de resultados REBA',
	'Puntaje REBA del lugar de trabajo': 'Pontuação REBA do posto de trabalho',
	'Left leg': 'Pierna izquierda',
	'Right leg': 'Pierna derecha',
	'Load less than 5kg (intermittent)': 'Carga inferior a 5 kg (intermitente)',
	'Load from 5kg to 20kg (intermittent)': 'Carga de 5kg a 20kg (intermitente)',
	'Load from 5kg to 20kg (static or repetitive)': 'Carga de 5 kg a 20 kg (estática o repetitiva)',
	'Greater than 20 kg of repetitive load or rapid build up of force': 'Carga repetitiva superior a 20kg o golpes',
	Unacceptable: 'Inaceptable',
	'Repetitions per minute': 'Repeticiones por minuto',
	Force: 'Fuerza',
	Load: 'Carga',
	'Select the load': 'Seleccione la carga',
	'Select the number of repetitions': 'Seleccione el número de repeticiones',
	'Score left': 'Score izquierda',
	'Score right': 'Score derecha',
	Acceptable: 'Aceptable',
	Investigate: 'Investigar',
	'Investigate and change soon': 'Investigar y cambiar rápido',
	'Investigate and change now': 'Investigar y cambiar ahora',
	'Investigate and Implement change': 'Investigar y aplicar rápido',
	'Implement change': 'Aplicar los cambios',
	'REBA Score': 'Score REBA',
	'RULA Score': 'Score RULA',
	'The 4 most critical body parts': 'Las 4 partes del cuerpo más críticas',
	'Viewing the selected body part': 'Visualización de la parte seleccionada',
	minute: 'minuto',
	'Requested video data is old, please upload to continue':
		'Los datos de video solicitados son antiguos, súbalos para continuar',
	'View score': 'Ver puntuación',
	'Unable to view': 'No se puede ver',
	'Worst grade found in time': 'Peor score encontrado en el tiempo',
	'The results obtained in each body part may be impacted by the shape and position of filming, and the identification of the person being analyzed may be interfered by objects present in the operating location. The angle data shown are approximate values, dependent on the adopted filming technique. If there is no data, the algorithm did not identify the points to determine the angle, so it is important to observe filming techniques.':
		'Los resultados obtenidos en cada parte del cuerpo pueden verse afectados por la forma y posición de la grabación, y la identificación de la persona que está siendo analizada puede sufrir interferencias debido a objetos presentes en el lugar de la operación. Los datos de ángulo mostrados son valores aproximados, dependiendo de la técnica de filmación adoptada. En caso de no tener datos, el algoritmo no identificó los puntos para determinar el ángulo, por lo tanto, es importante observar técnicas de filmación.',

	// Angle time
	'Angle by time': 'Ángulo en el Tiempo',
	'Create risk range': 'Crear rango de riesgo',
	'Report Results - Angle by Time': 'Reporte de resultados - Ángulo en el Tiempo',
	'Please fill all required fields': 'Por favor llene todos los campos requeridos',

	// ACTION PLANS
	'Action plans': 'Plan de acción',
	'Do you want to delete this action plan?': '¿Desea eliminar este plan de acción?',
	'Action plan created successfully': 'Plan de acción creado con éxito',
	'Create action plan': 'Crear plan de acción',
	'Please enter a valid description': 'Por favor ingrese una descripción válida',
	'Action plan description': 'Descripción del plan de acción',
	'Please enter a valid action plan description': 'Ingrese una descripción válida del plan de acción',
	'Person responsible': 'Persona responsable',
	Responsible: 'Persona responsable',
	'Who is responsible for this action plan?': '¿Quién es el responsable de este plan de acción?',
	'Describe the situation found and the preventive measures already implemented (if exist)':
		'Describa la situación encontrada y las medidas preventivas ya implementadas (si existen)',
	'Finish editing your other task first': 'Primero termine de editar la otra tarea',
	'Add a new task': 'Agregar una nueva tarea',
	History: 'Histórico',
	'Finish by: ': 'Terminar por: ',
	'On time': 'En el plazo',
	Overdue: 'Tarde',
	'Finishing in a week': 'Terminando en una semana',
	'Person responsible updated': 'Responsable del plan de acción actualizado',
	'Deadline updated': 'Fecha límite del plan de acción actualizado',
	Tasks: 'Tareas',
	'Task created': 'Tarea creada',
	'Task deleted': 'Tarea eliminada',
	'Task renamed': 'Tarea renombrada',
	'Task type changed': 'Tipo de tarea cambiado',
	'Task and type changed': 'Tarea y tipo cambiados',
	'Task checked': 'Tarea terminada',
	'Task unchecked': 'Tarea deshecha',
	'Action plan created': 'Plan de acción creado',
	'Action plan renamed': 'Plan de acción renombrado',
	'Status changed': 'Estado cambiado',
	'Deadline changed': 'Término cambiado',
	'by ': 'por ',
	'Successfully updated action plan data': 'Datos del plan de acción actualizados con éxito',
	'Responsible user changed': 'Cambio de responsable',
	'Action plan title': 'Título del plan de acción',
	Title: 'Título',
	'Select a file': 'Seleccione un archivo',
	'Please enter a valid title': 'Por favor ingrese un título válido',
	'Please enter a responsible': 'Por favor ingrese un responsable',
	'Please enter a valid date': 'Por favor introduzca una fecha valida',
	'Search company': 'Buscar empresa',
	'Search industrial site': 'Buscar centro de trabajo',
	'Select a condition': 'Selecione uma condição',
	Eliminate: 'Eliminar',
	Control: 'Controlar',
	Compensate: 'Compensar',
	ELIMINATE: 'ELIMINAR',
	CONTROL: 'CONTROLAR',
	COMPENSATE: 'COMPENSAR',

	// KIM - MHO
	'Error, cannot get "': 'Error, no se puede obtener "',
	'" from "': '" de "',
	'Total duration of this sub-activity per working day': 'Duración total de la actividad por día trabajado',
	'Type of force exertion in the finger/hand area within a “standard minute”':
		'Fuerza ejercida sobre el área de la mano/dedo en un "minuto estándar"',
	'Force transfer / gripping conditions': 'Transferencia de fuerza/condiciones de agarre',
	'Hand/arm position and movement': 'Posición y movimiento de manos/brazos',
	'Unfavourable working conditions': 'Condiciones laborales desfavorables',
	'Work organization / temporal distribution': 'Organización del trabajo / distribución del tiempo',
	'Body posture/movement': 'Postura y movimiento del cuerpo',
	'Very low': 'Muy baja',
	Moderate: 'Moderada',
	'Peak forces': 'Fuerzas máximas',
	'Powerful hitting': 'Golpes fuertes',
	'Up to 15% Fmax. Button actuation, shifting, ordering, material guidance, insertion of small parts.':
		'Hasta el 15% de Fmáx. Activación de botón, desplazamiento, clasificación, orientación de materiales, inserción de piezas pequeñas.',
	'Up to 30% Fmax. Gripping, joining small work pieces by hand or with small tools.':
		'Hasta 30% Fmáx. Agarrar o unir piezas pequeñas a mano o con herramientas pequeñas.',
	'Up to 50% Fmax. Turning, winding, packaging, grasping, holding or joining parts, pressing in, cutting, working with small powered hand tools.':
		'Hasta el 50% de Fmax. Torcer, enrollar, empaquetar, agarrar, sostener o unir piezas, presionar, cortar, trabajar con pequeñas herramientas manuales motorizadas.',
	'Up to 80% Fmax. Cutting involving major element of force, working with small staple guns, moving or holding parts or tools.':
		'Hasta el 80% de Fmax. Corte que involucre un gran elemento de fuerza, trabajar con pequeñas pistolas de grapas, mover o sostener piezas o herramientas.',
	'More than 80% Fmax. Tightening, loosening bolts, separating, pressing in.':
		'Más del 80% de Fmáx. Apretar, aflojar tornillos, separar, presionar.',
	'Hitting with the ball of the thumb, palm of the hand or fist.':
		'Golpear con la punta del pulgar, la palma o el puño.',
	'Optimum force transfer/application': 'Transferencia/aplicación ideal de fuerza',
	'Restricted force transfer/application': 'Transferencia/aplicación restringida de fuerza',
	'Force transfer/application considerably hindered': 'Transferencia/aplicación de fuerza considerablemente afectada',
	'Optimum force application': 'Aplicación ideal de fuerza',
	'Restricted force application': 'Aplicación restringida de fuerza',
	'Force application considerably hindered': 'Aplicación de fuerza considerablemente afectada',
	'Working objects are easy to grip (e.g. bar-shaped, gripping grooves) / good ergonomic gripping design (grips, buttons, tools).':
		'Los objetos de trabajo son fáciles de agarrar (p. ej., en forma de barra, ranuras de agarre) / buen diseño de agarre ergonómico (asas, botones, herramientas).',
	'Greater holding forces required / no shaped grips.':
		'Se requieren mayores fuerzas de agarre / asas sin formato ergonómico.',
	'Working objects hardly possible to grip (slippery, soft, sharp edges) / no or only unsuitable grips.':
		'Objetos de trabajo difíciles de sostener (resbaladizos, blandos, con bordes afilados) / solo hay asas inadecuadas o ninguna.',
	Restricted: 'Restringido',
	Unfavourable: 'Desfavorable',
	'Position or movements of joints in the middle (relaxed) range, only rare deviations, no continuous static arm posture, hand-arm rest possible as required.':
		'Posición o movimientos de las articulaciones en el intervalo medio (relajado), solo desviaciones raras / sin postura estática continua del brazo / apoyo mano-brazo posible según sea necesario.',
	'Occasional positions or movements of the joints at the limit of the movement ranges, occasional long continuous static arm posture.':
		'Posiciones o movimientos ocasionales de las articulaciones en los límites de las amplitudes de movimiento, postura del brazo ocasionalmente estática, larga y continua.',
	'Frequent positions or movements of the joints at the limit of the movement ranges, frequent long continuous static arm posture.':
		'Posiciones o movimientos frecuentes de las articulaciones en los límites de las amplitudes de movimiento, postura del brazo frecuentemente estática, larga y continua.',
	'Constant positions or movements of the joints at the limit of the movement ranges, constant long continuous static arm posture.':
		'Posiciones o movimientos constantes de las articulaciones en los límites de las amplitudes de movimiento, postura del brazo constantemente estática, larga y continua.',
	'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variación frecuente de la carga de trabajo física debido a otras actividades (incluidos otros tipos de carga de trabajo física) / sin una secuencia estrecha de cargas de trabajo físicas más altas dentro de un tipo de carga de trabajo física durante un solo día de trabajo.',
	'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variación rara de la carga de trabajo física debido a otras actividades (incluidos otros tipos de carga de trabajo física) / secuencia ocasionalmente restringida de cargas de trabajo físicas más altas dentro de un tipo de carga de trabajo física durante un único día de trabajo.',
	'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.':
		'Ninguna/casi ninguna variación en la carga de trabajo física debido a otras actividades (incluidos otros tipos de carga de trabajo física) / secuencia estrecha y frecuente de cargas de trabajo físicas más altas dentro de un tipo de carga de trabajo física durante un único día de trabajo con picos elevados de carga simultáneos.',
	'There are no unfavourable working conditions, i.e. reliable recognition of detail / no dazzle / good climatic conditions.':
		'No hay condiciones de trabajo desfavorables, es decir, reconocimiento confiable de detalles / sin deslumbramientos / buenas condiciones climáticas.',
	'Occasionally impaired detail recognition due to dazzle or excessively small details difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Reconocimiento de detalles ocasionalmente afectado debido a deslumbramiento o detalles excesivamente pequeños, condiciones difíciles como corriente de aire, frío, humedad y/o concentración perturbada debido al ruido.',
	'Frequently impaired detail recognition due to dazzle or excessively small details frequently difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Reconocimiento de detalles frecuentemente afectado debido a deslumbramiento o detalles excesivamente pequeños, condiciones frecuentemente difíciles como corrientes de aire, frío, humedad y/o concentración perturbada debido al ruido.',
	'Alternation between sitting and standing, alternation between standing and walking, dynamic sitting possible':
		'Cambiar entre estar sentado y de pie, cambiar entre estar de pie y caminar, es posible sentarse de forma dinámica',
	'Trunk inclined forward only very slightly': 'Tronco ligeramente inclinado hacia delante',
	'No twisting and/or lateral inclination of the trunk identifiable':
		'Sin torsión y/o inclinación lateral del tronco identificable.',
	'Head posture: variable, head not inclined backward and/or severely inclined forward or constantly moving':
		'Postura de la cabeza: variable, cabeza no inclinada hacia atrás y/o severamente inclinada hacia adelante o en constante movimiento',
	'No gripping above shoulder height / no gripping at a distance from the body':
		'Sin agarre por encima de la altura del hombro/Sin agarre a la distancia del cuerpo',
	'Predominantly sitting or standing with occasional walking':
		'Predominantemente sentado o de pie con caminata ocasional',
	'Trunk with slight inclination of the body towards the work area':
		'Tronco con ligera inclinación del cuerpo hacia la zona de trabajo',
	'Occasional twisting and/or lateral inclination of the trunk identifiable':
		'Torsión ocasional y/o flexión del tronco identificable lateralmente',
	'Occasional deviations from good “neutral” head posture/movement':
		'Desviaciones ocasionales de una buena postura/movimiento neutro de la cabeza',
	'Occasional gripping above shoulder height / occasional gripping at a distance from the body':
		'Convulsiones ocasionales por encima de la altura del hombro / convulsiones ocasionales a distancia del cuerpo',
	'Exclusively standing or sitting without walking': 'Exclusivamente de pie o sentado sin caminar',
	'Trunk clearly inclined forward and/or frequent twisting and/or lateral inclination of the trunk identifiable':
		'Tronco claramente inclinado hacia adelante y/o torsión frecuente y/o inclinación lateral del tronco identificable',
	'Frequent deviations from good “neutral” head posture/movement':
		'Frecuentes desviaciones de una buena postura/movimiento "neutro" de la cabeza',
	'Head posture hunched forward for detail recognition / restricted freedom of movement':
		'Postura de la cabeza curvada hacia adelante para reconocimiento de detalles/libertad de movimiento restringida',
	'Frequent gripping above shoulder height / frequent gripping at a distance from the body':
		'Agarre frecuente por encima de la altura del hombro/agarre frecuente a distancia del cuerpo',
	'Trunk severely inclined forward / frequent or long-lasting bending':
		'Tronco muy inclinado hacia adelante/flexión frecuente o prolongada',
	'Work being carried out in a kneeling, squatting, lying position':
		'Trabajo realizado en posición de rodillas, agachado y acostado',
	'Constant twisting and/or lateral inclination of the trunk identifiable':
		'Torsión constante y/o inclinación lateral del tronco identificable',
	'Body posture strictly fixed / visual check of action through magnifying glasses or microscopes':
		'Postura corporal estrictamente fija/verificación visual de la acción a través de lupas o microscopios',
	'Constant deviations from good “neutral” head posture/movement':
		'Desviaciones constantes de una buena postura/movimiento neutral de la cabeza',
	'Constant gripping above shoulder height / constant gripping at a distance from the body':
		'Agarre constante a distancia del hombro/Agarre constante a distancia del cuerpo',
	'Manual Handling': 'Manejo Manual',
	'Manual Handling Operations': 'Operaciones de Manejo Manual',
	'KIM - Manual Handling Operations': 'KIM - Operaciones de Manejo Manual',
	'Total duration per working day': 'Duración total por día trabajado',
	'Type of force exertion in the finger/hand': 'Fuerza ejercida en el área de la mano/dedo',

	'Possible health consequences': 'Posibles consecuencias para la salud',
	Measures: 'Medidas',
	'Physical overload is unlikely.': 'La sobrecarga física no es probable.',
	'No health risk is to be expected.': 'No se esperan riesgos para la salud.',
	'None.': 'Ninguno.',
	'Physical overload is possible for less resilient persons.':
		'La sobrecarga física es posible para las personas menos resilientes.',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Fatiga, problemas de ajuste de bajo grado que se pueden compensar durante el tiempo libre.',
	'For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		'Para las personas menos resilientes, los cambios en el lugar de trabajo y otras medidas preventivas pueden ayudar.',
	'Physical overload is possible for normally resilient persons.':
		'La sobrecarga física es posible para las personas normalmente resilientes.',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.':
		'Trastornos (dolor), posiblemente incluyendo disfunciones, reversibles en la mayoría de los casos, sin manifestación morfológica.',
	'Workplace redesign and other prevention measures should be considered.':
		'Se deben considerar cambios en el lugar de trabajo y otras medidas preventivas.',
	'Physical overload is likely.': 'La sobrecarga física es probable.',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'Trastornos y/o disfunciones más pronunciados, daño estructural con significado patológico.',
	'Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'Los cambios en el lugar de trabajo son necesarios, se deben considerar otras medidas preventivas.',
	'Slightly increased': 'Ligeramente aumentada',
	'Substantially increased': 'Incrementado sustancialmente',
	'Type of force exertion in the finger/hand area': 'Fuerza ejercida en el área de la mano/dedo',
	'Intensity of force exertion in the finger/hand area within a “standard minute”':
		'Fuerza ejercida sobre el área de la mano/Intensidad de la fuerza ejercida en el área de la mano/dedos en un "minuto estándar"',
	'No measures need to be taken.': 'No es necesario tomar medidas.',
	Holding: 'Sosteniendo',
	Moving: 'Moviendo',
	'seconds holding / minute': 'segundos sosteniendo / min.',
	'movements / minute': 'movimientos / min.',
	'Left arm': 'Brazo izquierdo',
	'Right arm': 'Brazo derecho',

	'Time and force': 'Tiempo y fuerza',
	Conditions: 'Condiciones',
	Posture: 'Postura',

	'Select the body parts you are interested in': 'Selecciona las partes del cuerpo que te interesan',
	'Select all': 'Seleccionar todo',

	'Shoulder L': 'Hombro I',
	'Shoulder R': 'Hombro D',
	'Elbow L': 'Codo I',
	'Elbow R': 'Codo D',
	'Leg L': 'Pierna I',
	'Leg R': 'Pierna D',

	Cycle: 'Ciclo',
	'Select one of the vehicles or a wheel type:': 'Seleccione uno de los vehículos o un tipo de rueda',
	Conveyors: 'Transportadores',
	Cranes: 'Grúas',
	'Driveway conditions:': 'Condiciones de la calzada:',
	'Select the mass to be transported (in kg):': 'Seleccione la masa a transportar (en kg):',
	'Inclination or stairs:': 'Pendiente o escaleras:',
	'Conditions:': 'Condiciones:',

	'Increased movement speed (approx. 1.0 up to 1.3 m/s)': 'Mayor velocidad de movimiento (aprox. 1,0 hasta 1,3 m/s)',

	'Cannot change a consolidated analysis': 'No se puede cambiar un análisis consolidado',
	'Comments successfully saved': 'Comentarios guardados correctamente',

	'Action plan deleted successfully': 'Plan de acción eliminado con éxito',
	'Task created successfully': 'Tarea creada con éxito',
	'Task deleted successfully': 'Tarea eliminada con éxito',
	'Task updated successfully': 'Tarea actualizada con éxito',

	'Ops... something happened!': 'Ups... ¡algo pasó!',
	'It was not possible to save your information': 'No se puede guardar su información',
	'No task available': 'No hay tareas disponibles',
	day: 'día',
	days: 'días',
	'Total duration per day worked': 'Duración total por día trabajado',
	'Force exerted on hand/fingers (L|R)': 'Fuerza ejercida en mano/dedos (I|D)',
	'Power transfer/gripping conditions': 'Transferencia de potencia/condiciones de agarre',

	// Super EWA File History
	'Selected Files': 'Archivos seleccionados',
	'Worst score': 'Peor nota',
	'Open File List': 'Abrir lista de archivos',
	'EWA deleted successfully': 'AEP eliminado con éxito.',

	//Others words
	Likelihood: 'Probabilidad',
	Exposure: 'Exposición',
	'Possible Injuries/Harms': 'Posibles lesiones/daños',
	Job: 'Actividad',
	'Assignment note': 'Nota de tarea',
	'Hours worked in the day': 'Horas trabajadas en el día',
	'Weight Limit recommended (WLR)': 'Límite de peso recomendado (LPR)',
	'Lifting index (LI)': 'índice de elevación (IL)',
	Comment: 'Comentarios',
	Particularities: 'Particularidades',
	Production: 'Producción',
	'Working regime': 'Régimen de trabajo',
	EWA: 'AEP',
	Action: 'Acción',
	'New document': 'Nuevo documento',
	'File history': 'Historial del archivo',

	//Action plans cards
	'Fill in the details above': 'Complete los detalles arriba',

	'Risk Acceptable': 'Riesgo Aceptable',

	'Risk Moderate': 'Riesgo Moderado',
	'Improvement action': 'Acción de mejora',

	'Risk High': 'Riesgo Alto',
	'Immediate action for improvement and reassess': 'Acción inmediata de mejora y reevaluación',

	'Risk Very High': 'Riesgo Muy Alto',
	'Perform Ergonomic Work Analysis': 'Realizar análisis de trabajo ergonómico',

	'Risk Serious and Imminent': 'Riesgo Grave e Inminente',
	'Stop the activity and carry out an Ergonomic Work Analysis':
		'Detener la actividad y realizar un Análisis Ergonómico del Trabajo',

	//Possible injuries
	'Possible injuries': 'Posibles lesiones',
	'Fatigue complaints': 'Quejas de fatiga',
	Absenteeism: 'Absentismo',
	'Musculoskeletal complaints': 'Quejas musculoesqueléticas',
	'Complaints of mental overload': 'Quejas de sobrecarga mental',
	'Complaints of irritability': 'Quejas de irritabilidad',
	'Nonconforming products': 'Productos no conformes',
	'Low back pain complaints': 'Quejas de lumbalgia',
	'Elbow pain': 'Dolor de codo',
	'Shoulder pain': 'Dolor de hombro',
	Headache: 'Dolor de cabeza',
	'Unexcused absences': 'Ausencias injustificadas',
	'Neck pain': 'Dolor de cuello',
	'Wrist pain': 'Dolor de muñeca',

	//Consequence
	Consequence: 'Consecuencia',
	'Severe with fatalities and serious injuries': 'Severo con muertos y heridos graves',
	'Temporary disability': 'Incapacidad temporal',
	'Injuries with leave': 'Lesiones con incapacidad',
	'Severe with possibility of death or disability': 'Grave con posibilidad de muerte o invalidez',
	'Superficial injuries': 'Lesiones / daños superficiales',
	'Catastrophic with numerous deaths': 'Catastrófico con numerosas muertes',

	//Probability
	Probability: 'Probabilidad',
	'Most likely and expected outcome': 'Resultado más probable y esperado',
	'Extremely remote possibility': 'Posibilidad extremadamente remota',
	'Practically impossible, never happened': 'Prácticamente imposible, nunca sucedió',
	'Unlikely, but it has happened': 'Improbable, pero ha pasado',
	'Possible outcome': 'Resultado posible',
	'Rare but possible': 'Raro pero posible',

	//Exhibition
	'Remotely (never occurred)': 'Remotamente (nunca ocurrió)',
	'Rarely (occurs in short bursts without definition)': 'Raramente (ocurre en ráfagas cortas sin definición)',
	'Irregular (once a month to once a year)': 'Irregular (una vez al mes a una vez al año)',
	'Occasionally (once a week to once a month)': 'Ocasionalmente (una vez a la semana a una vez al mes)',
	'Frequent (at least once a day)': 'Frecuente (al menos una vez al día)',
	'Continues (several times a day)': 'Continúa (varias veces al día)',
	'Remotely (>90% green)': 'Remotamente (>90% verde)',
	'Rarely (80 to 89% green)': 'Raramente (80 a 89% verde)',
	'Irregular (50 to 79% green)': 'Irregular (50 a 79% verde)',
	'Occasionally (30 to 49% green)': 'Ocasionalmente (30 a 49% verde)',
	'Frequent (10 to 29% green)': 'Frecuente (10 a 29% verde)',
	'Continues (<9% green)': 'Continúa (<9% verde)',

	//Steps erros
	'Select the injury': 'Seleccione las lesiones',
	'Select the injuries': 'Seleccione las lesiones',
	'Select the consequence': 'Seleccione la consecuencia',
	'Select the probability': 'Seleccione la probabilidad',
	'Select the exposure': 'Seleccione la exposición',
	"Some required steps wasn't filled": 'No se completaron algunos pasos obligatorios',
	"Some required step wasn't filled": 'No se completaron algunos pasos obligatorios',

	//Step 1
	Informations: 'Informaciones',
	'Select the sector': 'Seleccione el sector',
	Analyst: 'Analista',
	'Insert the analyst': 'Inserte el analista',
	'Insert the responsibility': 'Insertar la responsabilidad',
	'Insert the cell': 'Introduce la célula',
	'Insert the activity': 'Insertar la actividad',
	'Insert the workstation': 'Insertar el de trabajo',
	'Enter the collection date': 'Introduce la fecha de recogida',

	//Step 2
	'Work Conditions': 'Condiciones de trabajo',
	'Select the constraints perceived in the evaluated work condition':
		'Seleccione las restricciones percibidas en la condición de trabajo evaluada',
	'Work schedule': 'Horario de trabajo',
	'Summary description of the place': 'Descripción resumida del lugar',
	'Simplified description of the expected task': 'Descripción simplificada de la tarea prevista',
	'Simplified description of the task performed': 'Descripción simplificada de la tarea realizada',

	//Step 3
	'Working population': 'Población activa',
	"Worker's verbalization": 'Verbalización de los trabajadores',
	'Self-evaluation': 'Autoevaluación',
	'What is the level of stress perceived by the worker when performing the task?':
		'¿Cuál es el nivel de estrés que percibe el trabajador al realizar la tarea?',
	'Nothing stressful': 'Nada estresante',
	'Little stressful': 'Poco estresante',
	'Working population must be greater than 0': 'La población de los trabajadores debe ser mayor que 0',
	'Total working population must be greater than 0': 'La población de los trabajadores debe ser mayor que 0',
	workers: 'trabajadores',
	Stressful: 'Estresante',
	'Very stressful': 'Muy estresante',
	'Extremely stressful': 'Extremadamente estresante',

	//Step 4
	'Workday and concentration at work': 'Jornada y concentración en el trabajo',
	'Working hours of more than 8 hours a day': 'Jornada de trabajo superior a 8 horas por día',
	'Existence of long and frequent overtime hours of work':
		'Existencia de largas y frecuentes horas extras de trabajo',
	'Stays long time continuous operation': 'Permanece por largo tiempo en operación continua',
	'Insufficient rest interval': 'Intervalo de descanso insuficiente',
	'Insufficient rest break': 'Intervalo de descanso insuficiente',
	'Insufficient rest days': 'Días de descanso insuficientes',
	'Unbalanced concentrations of work in a day, week, month or year':
		'Concentraciones desequilibradas de trabajo en un día, semana, mes o año',
	'Unbalanced concentrations of work among workers': 'Concentraciones desequilibradas de trabajo entre trabajadores',
	'Insufficient rest between shifts (less than 11 hours)': 'Descanso insuficiente entre turnos (menos de 11 h)',
	'Remains long time continuous operation': 'Permanece por largo tiempo en operación continua',

	//Step 5
	'Kind of work': 'Tipo de trabajo',
	'Lift and/or carry heavy objects': 'Levanta y/o carga objetos pesados',
	'Apply the tool': 'Aplicar la herramienta',
	'Load surveys': 'Cargar encuestas',
	'Lifts and/or carries objects farther than 60 cm': 'Levanta y/o carga objetos a más de 60 cm de distancia',
	'Performs tasks that require great strength': 'Realiza tareas que requieren gran fuerza',
	'Performs tasks that require pronounced pushing and pulling forces':
		'Realiza tareas que requieren fuerzas acentuadas para empujar y tirar',
	'Performs repetitive work': 'Realiza trabajo repetitivo',
	'Performs work that requires frequent finger, hand, or arm movements':
		'Realiza trabajo que requiere movimientos frecuentes de dedo, mano o brazo',
	'Do intensive work with a keyboard or other input devices':
		'Realiza trabajo intensivo con un teclado u otros dispositivos de entrada de datos',
	'Perform precision work': 'Realizar trabajo de precisión',
	'Performs activities with high visual requirements':
		'Realiza actividades que requieren elevados requisitos visuales',

	//Step 6
	'Postures and movements': 'Posturas y movimientos',
	'Stays in uncomfortable postures and movements': 'Permanece en posturas y movimientos incómodos',
	'Performs continuous and/or highly frequent change in joint positions':
		'Realiza cambios continuos y/o altamente frecuentes en las posiciones de las articulaciones',
	'Stays in constrained posture for long duration': 'Permanece en postura restringida durante mucho tiempo',
	'Walk for long duration and/or long distance (on a horizontal surface as well as an inclined surface)':
		'Realiza caminatas de larga duración y/o larga distancia (en superficie horizontal, así como en una superficie inclinada)',
	'Performs frequent stair climbing': 'Realiza subida frecuente de escaleras',
	'Remains in a sedentary/standing position for a long time': 'Permanece mucho tiempo en posición sedentaria/de pie',
	'Postures requirements (Kinebot)': 'Requisitos de posturas (Kinebot)',

	//Step 7
	'Influence of workspace and task factors': 'Influencia del espacio de trabajo y de factores de la tarea',
	'Workspace is inadequate and forces an uncomfortable posture or limited movement':
		'El espacio de trabajo es inadecuado y obliga a adoptar una postura incómoda o limita el movimiento',
	'Workstation layout requires excessive movement or uncomfortable postures':
		'El diseño de la estación de trabajo requiere movimientos excesivos o posturas incómodas',
	'The heights, distances and dimensions of controls or work surfaces are inadequate':
		'Las alturas, distancias y dimensiones de los controles o superficie de trabajo son inadecuadas',
	'Anthropometry Table x Furniture (cm)': 'Mesa de Antropometría x Mobiliario (cm)',
	'Add information': 'Añadir información',
	'Found (cm)': 'Encontrado (cm)',
	'Foreseen (cm)': 'Previsto (cm)',
	'Enter a location': 'Ingrese una ubicación',
	'Enter what was found': 'Ingrese lo encontrado',
	'Insert predicted': 'Insertar predicho',
	'Performs handling of work objects above the shoulder or below the knee':
		'Realiza manejo de objetos de trabajo por encima del hombro o por debajo de la rodilla',
	'The workspace forces the worker to maintain the same working posture':
		'El espacio de trabajo obliga al trabajador a mantener la misma postura de trabajo',
	'Handles work objects that are difficult to handle and slippery':
		'Maneja objetos de trabajo difíciles de manejar o resbaladizos',
	'Activity requires high-voltage contact or local pressure acting on the body':
		'La actividad requiere alta tensión de contacto o presión local que actúa sobre el cuerpo',

	//Step 8
	'Influence of psychosocial factors': 'Influencia de factores psicosociales',
	'Activity with mental overload or underload': 'Actividad con sobrecarga o subcarga mental',
	'Work pace with time pressure and high demands': 'Ritmo de trabajo con presión de tiempo y altas demandas',
	'Presence of work-related stress': 'Presencia de estrés relacionado con el trabajo',
	'Reports of low job satisfaction': 'Relatos de baja satisfacción en el trabajo',
	'Activity with lack of autonomy (low influence, low control)':
		'Actividad con falta de autonomía (baja influencia, bajo control)',
	'Low social support': 'Bajo apoyo social',

	//Step 9
	'Influence of environmental factors': 'Influencia de factores del medio ambiente',
	'Place with slippery and/or uneven floor': 'Local con piso resbaladizo y/o irregular',
	'There is exposure to whole body vibration (see ISO 2631)':
		'Existe exposición a vibración en todo el cuerpo (ver ISO 2631)',
	'There is exposure to whole body vibration (check ISO 2631)':
		'Existe exposición a vibración en todo el cuerpo (ver ISO 2631)',
	'Makes use of hand-arm vibration tools during work (see ISO 5349)':
		'Utiliza herramientas de vibración mano-brazo durante el trabajo (ver ISO 5349)',
	'Makes use of hand-arm vibration tools during work (check ISO 5349)':
		'Utiliza herramientas de vibración mano-brazo durante el trabajo (ver ISO 5349)',
	'Performs work using vehicles that transmit vibration throughout the body':
		'Realiza trabajo utilizando vehículos que transmiten vibración en todo el cuerpo',
	'Hot or cold working environment': 'El ambiente de trabajo es caliente o frío',
	'Location with background noise': 'Lugar con ruido de fondo',

	//Step 10
	'Influence of organizational factors': 'Influencia de los factores organizacionales',
	'Production standards are not clear or understood by workers':
		'Las normas de producción no son claras o no son comprendidas por los trabajadores',
	'The operative mode descriptions are insufficient or wrong':
		'Las descripciones de los modos operativos son insuficientes o incorrectas',
	'The time to carry out the activities is inadequate': 'El tiempo para realizar las actividades es inadecuado',
	'The pace of work is accelerated': 'El ritmo de trabajo es acelerado',
	'The content of the tasks is different from the technical means available':
		'El contenido de las tareas es diferente de los medios técnicos disponibles',
	'Task with high cognitive demand': 'Tarea con gran exigencia cognitiva',

	// Results
	'Anthropometry Table': 'Mesa de antropometría',
	'Consolidate analysis': 'Consolidar análisis',
	Deconsolidate: 'Desconsolidar',
	'Successfully consolidated analysis': 'Análisis consolidado con éxito',
	'Successfully deconsolidated analysis': 'Análisis no consolidado con éxito',
	'Failed to generate the report': 'Error al generar el informe',
	'Failed to generate PDF': 'Error al generar el PDF',

	Hazardous: 'Peligroso',
	'Movement by score (Kinebot/REBA)': 'Movimiento por puntuación (Kinebot/REBA)',
	'Cargo Handling (NIOSH)': 'Manejo de carga (NIOSH)',
	'Manual Handling (KIM)': 'Manipulación manual (KIM)',
	'Push and Pull (KIM)': 'Empujar y tirar (KIM)',
	'No more action plans available': 'No hay más planes de acción disponibles',
	'No more workstations available': 'No hay más puesto de trabajo disponibles',

	//Super Ergonomic Workplace Analysis

	//Error
	'length must be less than or equal to 50 characters long': 'la longitud debe ser menor o igual a 50 caracteres',

	'Ergonomic Workplace Analysis': 'Análisis Ergonómico Preliminar',
	'Ergonomic Analysis': 'Análisis Ergonómico',
	'Ergonomic analysis': 'Análisis ergonómico',

	'New Document': 'Nuevo documento',
	'File History': 'Historial de archivos',

	'Basic information': 'Información básica',
	'Basic Information': 'Información básica',
	// Basic Informartion Step
	'Report Information': 'Información del informe',
	'Report name': 'Nombre del informe',
	'Enter report name': 'Ingrese el nombre del informe',
	'Select a Company': 'Seleccione una empresa',
	'Select the reference date': 'Seleccione la fecha de referencia',
	'Select the report date': 'Seleccione la fecha del informe',

	// Select EWA Step
	'File Name': 'Nombre del archivo',
	'Created At': 'Creado en',
	'Date of creation': 'Fecha de creación',
	'Analyst Name': 'Nombre del analista',
	Submit: 'Entregar',

	//Signatures Step
	Signatures: 'Firmas',
	'Enter with a role': 'Entrar con un puesto de trabajo',
	'Enter with a name of responsible': 'Ingresar con nombre de responsable',
	'Add a new signature': 'Añadir una nueva firma',
	'Responsible name': 'Nombre responsable',

	// Conclusion Step
	'Check selected Files': 'Comprobar archivos seleccionados',
	'Files List': 'Lista de archivos',
	'Selected EWA': 'EWA seleccionado',
	'EWA created successfully': 'EWA creado con éxito',
	'Failed to upload EWA report.': 'Error al cargar el informe EWA.',

	// Super EWA File History
	'Preliminary Ergonomic Analyzes': 'Análisis Ergonómicos Preliminares',
	'Preliminary Ergonomic Analyses': 'Análisis Ergonómicos Preliminares',

	Location: 'Ubicación',
	Found: 'Encontró',
	Foreseen: 'Previsto',
	Condition: 'Condición',

	'NOT OK': 'NO OK',
	"Score's sum": 'Resumen',

	// Do not change due to AEP key - Influences the translation of action plans created by AEP
	'Trunk posture from Kinebot': 'Postura del tronco de Kinebot',
	'Trunk posture from kinebot': 'Postura del tronco de kinebot',
	'Neck posture from Kinebot': 'Postura del cuello de Kinebot',
	'Neck posture from kinebot': 'Postura del cuello de kinebot',
	'Left elbow posture from Kinebot': 'Postura del codo izquierdo de Kinebot',
	'Left elbow posture from kinebot': 'Postura del codo izquierdo de kinebot',
	'Right elbow posture from Kinebot': 'Postura del codo derecho de Kinebot',
	'Right elbow posture from kinebot': 'Postura del codo derecho de kinebot',
	'Left shoulder posture from Kinebot': 'Postura del hombro izquierdo de Kinebot',
	'Left shoulder posture from kinebot': 'Postura del hombro izquierdo de kinebot',
	'Right shoulder posture from Kinebot': 'Postura del hombro derecho de Kinebot',
	'Right shoulder posture from kinebot': 'Postura del hombro derecho de kinebot',

	'Failed to upload the file': 'Error al cargar el archivo',

	// Two-factor authentication
	// API Errors
	'Two-factor authentication not found': 'Autenticación de dos factores no encontrada',
	'Already created two-factor authentication': 'Autenticación de dos factores ya creada',
	'Invalid token': 'Simbolo no valido',
	'Token already validated': 'Token ya validado',
	'Token not yet validated': 'Token aún no validado',
	'Token expired': 'Ficha caducada',
	'Too early to resend email': 'Demasiado pronto para reenviar el correo electrónico',
	'Something went wrong checking token': 'Algo salió mal al verificar el token',
	'Authentication type same as default': 'Tipo de autenticación igual que el predeterminado',
	'Authentication type through app already enabled': 'Tipo de autenticación a través de la aplicación ya habilitada',
	'Two-factor authentication not enabled': 'Autenticación de dos factores no habilitada',
	'Too many tries!': '¡Demasiados intentos!',
	'Too many calls! Wait a moment to try again.': '¡Demasiadas llamadas! Espere un momento para volver a intentarlo.',

	// Hooks success message
	'Two-factor authentication generated successfully': 'Autenticación de dos factores generada con éxito',
	'Two-factor authentication validated successfully': 'Autenticación de dos factores validada con éxito',
	'Two-factor authentication method changed successfully':
		'El método de autenticación de dos factores cambió con éxito',
	'Token sent successfully': 'Token enviado con éxito',
	'Token resent successfully': 'Token reenviado con éxito',
	'Authenticator app enabled successfully': 'Aplicación de autenticación habilitada con éxito',
	'Two-factor authentication deleted successfully': 'La autenticación de dos factores se eliminó con éxito',
	'Two-factor authentication reset successfully': 'Restablecimiento de autenticación de dos factores con éxito',
	'Forced two-factor authentication enabled successfully':
		'Autenticación de dos factores forzada habilitada con éxito',
	'Forced two-factor authentication disabled successfully':
		'Autenticación de dos factores forzada deshabilitada con éxito',

	// My Account
	'Account overview': 'Descripción de cuenta',
	Profile: 'Perfil',
	'Street name': 'Nombre de la calle',
	'Available time': 'Tiempo disponible',
	'Time used': 'Tiempo utilizado',

	'Force two-factor authentication to all users': 'Forzar autenticación de dos factores a todos los usuarios',
	'When activated, all users will be forced to activate two-factor authentication on login.':
		'Cuando se activa, todos los usuarios se verán obligados a activar la autenticación de dos factores al iniciar sesión.',
	'Two-factor authentication': 'Autenticación de dos factores',
	'When activated, you will have to type a validation code every time you login.':
		'Cuando esté activado, deberá escribir un código de validación cada vez que inicie sesión.',
	'You can choose to receive your code by email or connect your authentication app with a QRCode.':
		'Puede optar por recibir su código por correo electrónico o conectar su aplicación de autenticación con un QRCode.',

	// Actions
	'Reset 2FA': 'Restablecer 2FA',
	'Deactivate 2FA': 'Desactivar 2FA',
	'Confirm your password to proceed with the desired action':
		'Confirme su contraseña para proceder con la acción deseada',
	'Deactivate two-factor authentication': 'Desactivar la autenticación de dos factores',
	'You are about to reset your authentication method.': 'Está a punto de restablecer su método de autenticación.',
	'Select a new desired authentication type and insert one of your recovery token to perform this operation.':
		'Seleccione un nuevo tipo de autenticación deseado e inserte uno de sus tokens de recuperación para realizar esta operación.',
	'Authentication method': 'Método',
	'Recovery token': 'Token de recuperación',
	'Authenticator app': 'Aplicación',
	'You are about to deactivate your 2FA.': 'Está a punto de desactivar su 2FA.',
	'Confirming this action will delete your two-factor authentication. Are you sure?':
		'Si confirma esta acción, se eliminará su autenticación de dos factores. ¿Está seguro?',
	'Heads up! Closing now will delete the token you generated. Want to continue?':
		'¡Alto! Si cierra ahora, se eliminará el token que generó. ¿Quieres continuar?',
	'Enable two-factor authentication': 'Habilitar autenticación de dos factores',
	'Select a method to generate your two-factor authentication':
		'Seleccione un método para generar su autenticación de dos factores',
	'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login.':
		'Con este método, escaneará un QRCode para poder usar su aplicación de autenticación favorita (como Google Authenticator o Microsoft Authenticator). Luego, todo lo que tiene que hacer es escribir el código que se muestra en su aplicación cuando inicia sesión.',
	'With this method, you will receive the login code in your registered email. At the time of login, you must fill in the indicated field with the code sent to your email.':
		'Con este método, recibirá el código de inicio de sesión en su correo electrónico registrado. Al momento de iniciar sesión, debe completar el campo indicado con el código enviado a su correo electrónico.',
	'You are about to generate your 2FA with your authenticator app.':
		'Está a punto de generar su 2FA con su aplicación de autenticación.',
	'Be sure to have your mobile phone with you ready to scan your code.':
		'Asegúrese de tener su teléfono móvil con usted listo para escanear su código.',
	'Validation code': 'Código de validación',
	'Validation token': 'Token de validación',
	'Enter the 6 digit code.': 'Ingrese el código de 6 dígitos.',
	'Recovery tokens': 'Fichas de recuperación',
	'For security reasons, we need you to do your two-factor validation':
		'Por razones de seguridad, necesitamos que hagas tu validación de dos factores',

	'You are about to generate your two-factor authentication via the app method. Make sure you are close to your cell phone, with the authentication app open.':
		'Está a punto de generar su autenticación de dos factores a través del método de la aplicación. Asegúrate de estar cerca de tu celular, con la app de autenticación abierta.',
	'You are about to generate your two-factor authentication via email method. Make sure you have your inbox open.':
		'Está a punto de generar su autenticación de dos factores a través del método de correo electrónico. Asegúrate de tener tu bandeja de entrada abierta.',

	'If you have not received your code via email, you can try again by clicking':
		'Si no ha recibido su código por correo electrónico, puede volver a intentarlo haciendo clic en',
	here: 'aquí',
	'Unable to generate token': 'No se puede generar el token',

	"Here are your recovery codes, which will be requested should you need to recover your account. Keep in mind that they will not be accessible again after this moment, so it's crucial that you save them securely.":
		'Aquí están tus códigos de recuperación, que se solicitarán en caso de que necesites recuperar tu cuenta. Ten en cuenta que no estarán accesibles de nuevo después de este momento, por lo que es crucial que los guardes de manera segura.',

	"Can't close yet. Read the instructions": 'No se puede cerrar todavía. Lee las instrucciones.',
	"Your company has asked for all users to keep two-factor authentication enabled for security reasons. You'll be asked to activate it again on your next login. Want to deactivate it anyway?":
		'Su empresa ha pedido a todos los usuarios que mantengan habilitada la autenticación de dos factores por razones de seguridad. Se le pedirá que la active nuevamente en su próximo inicio de sesión. ¿Desea desactivarla de todos modos?',

	Change: 'Cambiar',
	Security: 'Seguridad',
	'Account security settings': 'Configuración de seguridad de la cuenta',
	'Account information summary': 'Resumen de información de la cuenta',
	'Password must be at least 8 characters long': 'La contraseña debe tener al menos 8 caracteres',
	'The password must have a maximum of 20 characters': 'La contraseña debe tener como máximo 20 caracteres',
	'The password field is required': 'Se requiere el campo de contraseña',
	'Password field cannot be empty': 'El campo de contraseña no puede estar vacío',
	'The password must contain capital letters': 'La contraseña debe contener letras mayúsculas',
	'The password must contain lowercase letters': 'La contraseña debe contener letras minúsculas',
	'Password must contain numbers': 'La contraseña debe contener números',
	'The password must contain at least one special character':
		'La contraseña debe contener al menos un carácter especial',

	// Manage Companies
	'Manage industrial site': 'Gestionar planta industrial',
	'Update company name': 'Actualizar nombre de empresa',
	'Delete company': 'Eliminar empresa',
	'Total Industrial Sites': 'Total de Emplazamientos Industriales',
	'Create workstation': 'Crear puesto de trabajo',
	'Workstation name': 'Nombre de el puesto de trabajo',
	'Create sector': 'Crear sector',
	'Sector name': 'Nombre del sector',
	'Edit industrial site': 'Editar sitio industrial',
	'All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?':
		'¿Desea continuar? Se borrarán todos los datos, incluidas sus respectivas cargas, informes y contenidos.',
	'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'¿Desea continuar? Se eliminarán todas las empresas en esta organización, junto con sus respectivas cargas y informes.',
	'Edit sector': 'Editar sector',
	'Select a workstation': 'Seleccione un puesto de trabajo',
	'Please, select an industrial site': 'Por favor, seleccione un sitio industrial',
	'Please, select a sector': 'Por favor, seleccione un sector',
	'Please, select a workstation': 'Por favor, seleccione un puesto de trabajo',
	'Edit workstation': 'Editar puesto de trabajo',
	'You will create a new workstation with same name. Do you wish to proceed ?':
		'Crearás un nuevo puesto de trabajo con el mismo nombre. ¿Deseas proceder?',
	'You will create a new company with same name. Do you wish to proceed ?':
		'Crearás un nuevo sitio industrial con el mismo nombre. ¿Deseas proceder?',
	'You will create a new sector with same name. Do you wish to proceed ?':
		'Crearás un nuevo sector con el mismo nombre. ¿Deseas proceder?',
	'This name already exists. Do you wish to proceed ?': 'Este nombre ya existe. ¿Deseas continuar?',
	'Create new company': 'Crear nueva empresa',
	'You will create a new organization with same name. Do you wish to proceed ?':
		'Crearás una nueva empresa con el mismo nombre. ¿Deseas proceder?',
	'Failed to update the report': 'Error al actualizar el informe',
	'Failed to create the report': 'Error al crear el informe',
	'Analyzed person': 'Persona analizada',

	Person: 'Persona',
	Persons: 'Personas',
	'Identify people to be analyzed': 'Identificar a las personas a analizar.',
	'No person has been identified with more than 50% of accuracy.':
		'Ninguna persona ha sido identificada con más del 50% de precisión',
	'Please, record the video following the guidelines': 'Por favor, grabe el video siguiendo las pautas',
	Files: 'Archivos',
	// Recovery Two-Factor
	'Select a desired new method and enter one of the recovery tokens to perform the recovery':
		'Seleccione un nuevo método deseado e ingrese uno de los tokens de recuperación para realizar la recuperación',

	// Force Two-Factor
	'For security reasons, we need you to enable your two-factor validation':
		'Por razones de seguridad, necesitamos que habilites tu validación de dos factores',
	'Your company is requesting that all users have two-factor authentication enabled. Proceed to activate yours and login.':
		'Su empresa solicita que todos los usuarios tengan habilitada la autenticación de dos factores. Procede a activar el tuyo e inicie sesión.',
	Return: 'Volver',
	'Continue with Microsoft SSO': 'Continuar con Microsoft SSO',

	// Liberty Mutual
	'General informations': 'Informaciones Generales',
	'Material Handling': 'Manipulación de Material',
	'Material handling': 'Manipulación de material',
	'Invalid request data': 'Datos de solicitud no válidos',
	'Liberty Mutual report created successfully!': '¡Informe Liberty Mutual creado exitosamente!',
	'Liberty Mutual report updated successfully!': '¡Informe Liberty Mutual actualizado exitosamente!',
	'Liberty Mutual task not found': 'Tarea de Liberty Mutual no encontrada',
	'System of units not found': 'Sistema de unidades no encontrado',
	Lift: 'Levantar',
	Lifts: 'Levantamientos',
	lift: 'levantamiento',
	lifts: 'levantamientos',
	Lower: 'Bajar',
	Lowers: 'Bajamientos',
	lowers: 'bajamientos',
	lower: 'bajamiento',
	Push: 'Empujar',
	Pushes: 'Empujones',
	pushes: 'empujones',
	push: 'empujón',
	Pull: 'Tirar',
	Pulls: 'Tiradas',
	pulls: 'tiradas',
	pull: 'tirada',
	Carry: 'Cargar',
	Carries: 'Cargamentos',
	carries: 'cargamentos',
	carry: 'cargamento',
	'System of unit': 'Sistema de unidad',
	'Select the system of unit': 'Seleccione um sistema de unidad',
	'Select the movement of the task being evaluated': 'Seleccione el movimiento de la tarea que se está evaluando',
	'Insert the fields data for the selected task': 'Inserte los datos de los campos para la tarea seleccionada',
	'Start hand height': 'Altura inicial de la mano',
	'End hand height': 'Altura final de la mano',
	'Start hand distance': 'Distancia inicial de la mano',
	'End hand distance': 'Distancia final de la mano',
	'Initial force': 'Fuerza inicial',
	'Sustained force': 'Fuerza sostenida',
	'Horizontal distance': 'Distancia horizontal',
	'Vertical hand height': 'Altura vertical de la mano',
	'Hand coupling': 'Acoplamiento de la mano',
	'Object weight': 'Peso del objeto',
	Weight: 'Peso',
	'Select a hand coupling': 'Seleccione un acoplamiento de mano',
	'Insert a start height': 'Inserte una altura inicial',
	'Insert an end height': 'Inserte una altura final',
	'Insert a start distance': 'Inserte una distancia inicial',
	'Insert an end distance': 'Inserte una distancia final',
	'Insert an object weight': 'Inserte un peso de objeto',
	'Insert a frequency value': 'Inserte un valor de frecuencia',
	'Select a frequency interval': 'Seleccione un intervalo de frecuencia',
	'Insert an initial force': 'Inserte una fuerza inicial',
	'Insert a sustained force': 'Inserte una fuerza sostenida',
	Initial: 'Inicial',
	Sustain: 'Sostenida',
	'Insert a horizontal distance': 'Inserte una distancia horizontal',
	'Insert a vertical hand height': 'Inserte una altura vertical de la mano',
	'Select a task': 'Seleccione una tarea',
	'per hour': 'por hora',
	hour: 'hora',
	'per minute': 'por minuto',
	per: 'por',
	'Video duration': 'Duración del video',
	'Assessment date': 'Fecha de evaluación',
	'Population percentile': 'Percentil de la población',
	'Activity is safe for': 'Actividad es segura para',
	'Initial state': 'Estado inicial',
	'End state': 'Estado final',
	Displacement: 'Desplazamiento',
	Task: 'Tarea',
	Metric: 'Métrico',
	Imperial: 'Imperial',
	'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower':
		'Los datos ingresados para la altura inicial de la mano y la altura final de la mano indican que el tipo de tarea es Levantar, no Bajar',
	'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift':
		'Los datos ingresados para la altura inicial de la mano y la altura final de la mano indican que el tipo de tarea es Bajar, no Levantar',

	// Integrations
	'Built-in user groups': 'Grupos de usuarios integrados',
	'Connect your Microsoft Azure AD users': 'Conecte a sus usuarios de Microsoft Azure AD',
	'Invitation sent': 'Invitación enviada',
	'Microsoft Azure Active Directory - Users': 'Microsoft Azure Active Directory - Usuarios',
	'Failed to load user groups': 'No se pudieron cargar los grupos de usuarios',
	'User removed successfully!': '¡El usuario se eliminó correctamente!',
	'Failed to remove user': 'No se pudo eliminar la usuario',
	'Do you want to remove the user': '¿Quieres eliminar a la usuaria?',
	'Failed to update user': 'Error al actualizar el usuario',
	'Failed to load information': 'No se pudo cargar la información',
	'Investigate and implement change': 'Investigar y aplicar rápido',
	'Implement changes': 'Implementar cambios',
	Attention: 'Atención',

	// Upload Logos
	'Upload logos': 'Carga de Logos',
	'Use the same of the company': 'Usa el mismo de la empresa',
	'.jpeg or .png up to 5MB': '.jpeg o .png hasta 5MB',
	'Upload files': 'Cargar archivos',
	'Upload your company logo': 'Carga el logo de tu empresa',
	'Upload your industrial site logo': 'Carga el logo de tu sitio industrial',
	'Add a image': 'Agregar una imagen',
	'Image successfully added': 'La imagen fue agregada con éxito',
	'Image successfully changed': 'La imagen fue cambiada exitosamente.',
	'Uploaded picture': 'Foto subida',
	'Chanded picture': 'Foto cambiada',
	'Edit picture': 'Editar foto',
	Reset: 'Reiniciar',

	// Minutes Details
	'Track the minutes used on the platform during the desired period':
		'Seguimiento de los minutos utilizados en la plataforma durante el periodo deseado',
	'See more details': 'Ver más detalles',
	'Please select one or more': 'Por favor, seleccione uno o más',
	'The end date must be later than the start date': 'La fecha final debe ser mayor que la fecha de inicio',
	Granularity: 'Granularidad',
	'Select a granularity': 'Seleccione una granularidad',
	'Select a date': 'Seleccione una fecha',
	'Minutes used': 'Minutos utilizados',
	Start: 'Inicio',
	End: 'Final',
	Users: 'Usuarios',
	'Failed to search for companies': 'Fracaso en la búsqueda de empresas',
	'Error in searching for user information': 'Error al recuperar la información del usuario',

	'To Do': 'Pendientes',
	'TO DO': 'PENDIENTES',
	Doing: 'En curso',
	DOING: 'EN CURSO',
	Done: 'Terminadas',
	DONE: 'TERMINADAS',

	Status: 'Status',
	'Remember me': 'Recordarme automáticamente',
	'Add item': 'Añadir ítem',

	'Type of force exertion in the finger/hand (left | right)': 'Fuerza ejercida en la mano/dedo (E|D):',
	Password: 'Contraseña',
	Descending: 'Descendiente',
	Integrations: 'Integración',
	'Order by worst score': 'Ordenar por peor puntuación',
	'Order by deadline': 'Ordenar por plazo',
	'Select EWA': 'Seleccionar AEP',
	'Workstation score': 'Puntuación del puesto de trabajo',
	Deadline: 'Plazo de entrega',
	Responsibility: 'Cargo',

	'average (seconds holding / minute)': 'media (segundos sostenidos / minuto)',
	'average (movements / minute)': 'media (movimientos / minuto)',

	// Personalised Reports
	'Personalised reports': 'Informes personalizados',
	'Personalised report': 'Informe personalizado',
	'Created by': 'Creado por',
	'Edit report': 'Editar informe',
	'Created date': 'Fecha de creación',
	'New report': 'Nuevo informe',
	'Manage report': 'Administrar informe',
	Forward: 'Avanzar',
	'Require video': 'Requerir vídeo',
	Evaluator: 'Evaluador',
	'Require evaluator': 'Requerir evaluador',
	'Evaluator created successfully': 'Evaluador creado con éxito',
	'Select an evaluator': 'Seleccione un evaluador',
	'Require work condition': 'Requerir condiciones de trabajo',
	'Require characteristics': 'Requerir características',
	Acronym: 'Acrónimo',
	'Insert an acronym': 'Inserte un acrónimo',
	'Insert a report name': 'Insertar un nombre de informe',
	'Risk scale': 'Escala de riesgo',
	'Insert a risk scale': 'Insertar una escala de riesgo',
	'Recommended sections': 'Secciones recomendadas',
	'Create section': 'Crear sección',
	'Section title': 'Título de la sección',
	'Search by section name...': 'Buscar por nombre de sección...',
	'No sections selected': 'No hay secciones seleccionadas',
	'No more sections available': 'No hay más secciones disponibles',
	'No sections available': 'No hay secciones disponibles',
	'Section created successfully': 'Sección creada correctamente',
	'Section has already been added': 'Sección ya añadida',
	'Already created section': 'Sección ya creada',
	'Already created subsection': 'Subsección ya creada',
	'Selected sections': 'Secciones seleccionadas',
	'Recommended criteria': 'Criterios recomendados',
	'Create criterion': 'Crear criterio',
	'Criterion title': 'Título del criterio',
	'Search by criterion name...': 'Buscar por nombre de criterio...',
	'No criteria selected': 'No hay criterios seleccionados',
	'No more criteria available': 'No hay más criterios disponibles',
	'No criteria available': 'No hay criterios disponibles',
	'Criterion created successfully': 'Criterio creado con éxito',
	'Criterion has already been added': 'Criterio ya añadido',
	'Already created criterion': 'Criterio ya creado',
	'Selected criteria': 'Criterio seleccionado',
	'Section not found': 'Sección no encontrada',
	'Failed to create section': 'Error al crear sección',
	'Failed to update section': 'Error al actualizar la sección',
	'Criterion not found': 'Criterio no encontrado',
	'Failed to create criterion': 'Error al crear criterio',
	'Failed to update criterion': 'Error al actualizar criterio',
	'Indicate possible injuries/aggravations': 'Indicar posibles lesiones/agravaciones',
	'Link tool to analysis': 'Vincular herramienta a análisis',
	'Sections and criteria': 'Secciones y criterios',
	'No tool selected': 'No se ha seleccionado ninguna herramienta',
	'Selected tools': 'Herramientas seleccionadas',
	'Do not indicate possible injuries/aggravations': 'No indicar posibles lesiones/agravaciones',
	'Incomplete section': 'Sección incompleta',
	'Custom report created successfully': 'Informe personalizado creado con éxito',
	'Custom report updated successfully': 'Informe personalizado actualizado con éxito',
	'Report downloaded': 'Informe descargado',
	'Analysis downloaded successfully': 'Análisis descargado con éxito',
	'Want to delete this criterion?': '¿Desea eliminar este criterio?',
	'Want to delete this criterion? Deleting it will remove the section as well!':
		'¿Desea eliminar este criterio? Al eliminarlo, se eliminará también la sección".',
	"Kinebot's standard prioritisation matrix": 'Matriz de priorización estándar de Kinebot',
	'3 level prioritisation matrix': 'Matriz de priorización de 3 niveles',
	'6 level prioritisation matrix': 'Matriz de priorización de 6 niveles',
	'Select the restrictions perceived in the evaluated characteristics':
		'Seleccione las restricciones percibidas en las características evaluadas',
	'Particularities / Production / Working regime': 'Particularidades / Producción / Régimen de trabajo',
	'Select the restrictions perceived in the evaluated working condition':
		'Seleccione las restricciones percibidas en la condición de trabajo evaluada',
	'Insert work schedule': 'Insertar el horario de trabajo',
	'Work hours must be greater than 1': 'El horario de trabajo debe ser superior a 1',
	'Unauthorized requisition': 'Solicitud no autorizada',
	'Authorization header is missing': 'Falta la cabecera de autorización',
	'Failed to query the database': 'Error al consultar la base de datos',
	'Calculation per second not found': 'Cálculo por segundo no encontrado',
	'Business information not found': 'Información comercial no encontrada',
	'Custom report not found': 'Informe personalizado no encontrado',
	'Already created custom report': 'Informe personalizado ya creado',
	'Failed to create custom report': 'Error al crear informe personalizado',
	'Custom report result not found': 'No se ha encontrado el resultado del informe personalizado',
	'Already created custom report result': 'Informe personalizado ya creado".',
	'Failed to create custom report result': 'No se ha podido crear el informe personalizado".',
	'Work condition not found': 'Condición de trabajo no encontrada',
	'Already created work condition': 'Condición de trabajo ya creada',
	'Failed to create work condition': 'Error al crear condición de trabajo',
	'Characteristic not found': 'Característica no encontrada',
	'Already created characteristic': 'Característica ya creada',
	'Failed to create characteristic': 'No se ha podido crear la característica',
	'Criteria result not found': 'Resultado de criterios no encontrado',
	'Already created criteria result': 'Resultado de criterios ya creado',
	'Failed to create criteria result': 'Error al crear resultado de criterios',
	'Failed to update criteria result': 'Error al actualizar resultado de criterios',
	'Criteria injury not found': 'Criterios de lesión no encontrados',
	'Already created criteria injury': 'Criterios de lesión ya creados',
	'Failed to create criteria injury': 'Error al crear criterios de lesión',
	'Custom report score scale not found': 'No se ha encontrado la escala de puntuación del informe personalizado',
	'Worker self evaluation not found': 'No se ha encontrado la autoevaluación del trabajador',
	'Custom report setting not found': 'No se ha encontrado la configuración del informe personalizado',
	'Custom report step setting not found': 'No se ha encontrado la configuración de pasos del informe personalizado',
	'Criteria settings not found': 'No se ha encontrado la configuración de criterios',
	'Step key ergonomic tool not found': 'No se ha encontrado la herramienta ergonómica de pasos clave',
	'Failed to create step key ergonomic tool': 'No se ha podido crear la herramienta ergonómica de pasos clave',
	'Failed to update step key ergonomic tool': 'No se ha podido actualizar la herramienta ergonómica de pasos clave',
	'Evaluator not found': 'Evaluador no encontrado',
	'Already created evaluator': 'Evaluador ya creado',
	'Ergonomic tool not found': 'Herramienta ergonómica no encontrada',
	'Ergonomic tool category not found': 'Categoría de herramienta ergonómica no encontrada',
	'Action plan not found': 'Plan de acción no encontrado',
	'Failed to create an history': 'No se ha podido crear un historial',
	'Already created action plan': 'Plan de acción ya creado',
	'Action plan task not found': 'Tarea del plan de acción no encontrada',
	'Action plan history not found': 'No se ha encontrado el historial del plan de acción',
	'Preliminary analysis not found': 'Análisis preliminar no encontrado',
	'Analysis step not found': 'Paso de análisis no encontrado',
	'Default step not found': 'Paso predeterminado no encontrado',
	'Probabilty not found': 'Probabilidad no encontrada',
	'Injury not found': 'Lesión no encontrada',
	'Consequence not found': 'Consecuencia no encontrada',
	'Exposure not found': 'Exposición no encontrada',
	'Range risk not found': 'Riesgo de alcance no encontrado',
	'Failed to fetch risk tracks': 'No se han encontrado las pistas de riesgo".',
	'Organization not found': 'Organización no encontrada',
	'Organization still has registered companies': 'La organización aún tiene empresas registradas',
	'Failed to create an organization': 'Error al crear una organización',
	'Company not found': 'Empresa no encontrada',
	'Failed to create company': 'No se ha podido crear una empresa',
	'Sector not found': 'Sector no encontrado',
	'Workstation not found': 'Estación de trabajo no encontrada',
	'Corrupted video': 'Vídeo dañado',
	'File not found': 'Archivo no encontrado',
	'Parameters not found': 'Parámetros no encontrados',
	'Failed to read sent file': 'Error al leer el archivo enviado',
	'Failed to save file': 'Error al guardar el archivo',
	'Failed to create download url': 'Error al crear url de descarga',
	'File already queued': 'Archivo ya en cola',
	'File already processed': 'Archivo ya procesado',
	'Failed to save file information': 'Error al guardar la información del archivo',
	'Already registered user': 'Usuario ya registrado',
	'Failed to create customer plan': 'Error al crear plan de cliente',
	'Failed to create customer transaction': 'Error al crear transacción de cliente',
	'Customer transaction not found': 'Transacción de cliente no encontrada',
	'Customer transaction already completed': 'Transacción de cliente ya completada',
	'Product not found': 'Producto no encontrado',
	'Failed to create customer credits': 'Error al crear créditos de cliente',
	'Plan not found': 'Plan no encontrado',
	'Processed minutes limit reached': 'Se ha alcanzado el límite de minutos procesados',
	'Invalid event': 'Evento no válido',
	'Not authorized': 'No autorizado',
	'Failed to create a user permission': 'Error al crear un permiso de usuario',
	'Customer not found': 'Cliente no encontrado',
	'Unregistered customer': 'Cliente no registrado',
	'Already registered customer': 'Cliente ya registrado',
	'Failed to create customer': 'Error al crear cliente',
	'Failed to update customer': 'Error al actualizar cliente',
	'Report not found': 'Informe no encontrado',
	'Already created report': 'Informe ya creado',
	'Failed to create report': 'Error al crear informe',
	'Failed to create report pdf': 'Error al crear informe pdf',
	'Failed to update report': 'Error al actualizar informe',
	'Group not found': 'Grupo no encontrado',
	'User group not found': 'Grupo de usuarios no encontrado',
	'Failed to upload file to storage': 'Error al cargar archivo en el almacén',
	'Failed to create file signature': 'Error al crear firma de archivo',
	'Failed to download data': 'Fallo al descargar datos',
	'Reply queue communication failure': 'Fallo en la comunicación de la cola de respuesta',
	'1% to 10% of the cycle': '1% al 10% del ciclo',
	'11% to 20% of the cycle': '11% al 20% del ciclo',
	'21% to 30% of the cycle': '21% al 30% del ciclo',
	'31% to 50% of the cycle': '31% al 50% del ciclo',
	'51% to 75% of the cycle': '51% al 75% del ciclo',
	'76% to 100% of the cycle': '76% al 100% del ciclo',
	'Physical or cognitive discomfort': 'Malestar físico o cognitivo',
	'Muscle fatigue or mental overload': 'Fatiga muscular o sobrecarga mental',
	'Acute pain': 'Dolor agudo',
	'Illness with partial loss of working capacity - without time off work':
		'Enfermedad con pérdida parcial de la capacidad laboral - sin baja laboral',
	'Illness with total loss of working capacity - with time off work':
		'Enfermedad con pérdida total de la capacidad laboral - con baja laboral',
	'Disability retirement': 'Pensión de invalidez',
	'Illness - with leave': 'Enfermedad - con baja',
	'Up to 25% of the cycle': 'Hasta 25% del ciclo',
	'26% to 75% of the cycle': '26% al 75% del ciclo',
	Unlikely: 'Poco probable',
	Remote: 'Remoto',
	Occasional: 'Ocasional',
	Likely: 'Probable',
	Frequent: 'Frecuente',
	Certainly: 'Seguro',
	'Create/update tool': 'Crear/actualizar herramienta',

	'Driveway conditions are not applied for the selected vehicle.':
		'Las condiciones de entrada no se aplican para el vehículo seleccionado.',
	'Your organization uses single sign-on (SSO). Use your corporate email to access Kinebot.':
		'Su organización utiliza el inicio de sesión único (SSO). Utilice su correo electrónico corporativo para acceder a Kinebot.',
	'Sign in without SSO': 'Iniciar sesión sin SSO',
	'Enter a valid email': 'Introduzca un correo electrónico válido',
	'Enter your email': 'Introduce tu correo electrónico',
	'Work email': 'Correo electrónico corporativo',
	'Oops! Something happened.': '¡Ups! Ocurrió algo.',
	'Failed to fetch information': 'Error al obtener información',
	'Continue with SSO': 'Continuar con SSO',
	'Return to login without SSO': 'Volver al login sin SSO',
	'You are being redirected': 'Usted está siendo redirigido',
	'User not associated with an SSO account': 'Usuario no asociado a una cuenta de SSO',
	'Fail get user info': 'Error al obtener información del usuario',
	'The server encountered an unexpected situation.': 'El servidor se encontró con una situación inesperada.',
	'Please enter a valid email address.': 'Introduzca una dirección de correo electrónico válida.',
	'Report incomplete': 'Informe incompleto',

	// Reports list table
	'KIM PP score': 'Puntuación KIM PP',
	'KIM MHO score': 'Puntuación KIM MHO',
	'Items per page': 'Artículos por página',
	'Exposure average': 'Media de exposición',
	'Percentile (Man | Woman)': 'Percentil (Hombre | Mujer)',

	// Files list screen
	'Create report': 'Crear informe',
	'Edit file': 'Editar archivo',
	'Edit files': 'Editar archivos',
	'Edit file hierarchy': 'Editar jerarquía del archivo',
	'Files deleted successfully': 'Archivos eliminados con éxito',
	'File updated successfully': 'Archivos actualizado con éxito',
	'Files updated successfully': 'Archivos actualizados con éxito',

	/*USER MANAGEMENT*/
	'Do you wish to delete this user?!': '¿Deseas eliminar a este(a) usuario?!',
	'Insert a name': 'Insertar un nombre',
	'Insert an e-mail': 'Introducir un e-mail',
	Find: 'Encontrar',
	'You are creating user': 'Estas creando usuario',
	'is receiving permission level': 'está recibiendo permisos de nivel',
	'in the following companies and industrial sites:': 'en las siguientes empresas y centros industriales:',
	'Companies and Industrial Sites': 'Empresas y Centros de Trabajo',
	user: 'usuario',
	USER: 'USUARIO',
	MASTER: 'MAESTRO',
	from: 'de',
	'You are removing the permission level': 'Você está eliminando el permiso de nivel',
	'is a new user with the role': 'es un nuevo usuario con el nivel de permiso',
	'You are changing': 'Está cambiando el nivel de permiso de',
	"permission's level to": 'para',
	'Do you want to confirm the change?': '¿Quieres confirmar el cambio?',
	'Search a company or industrial site': 'Búsqueda de empresa o centro de trabajo',
	'Create user': 'Crear usuario',
	'is a new user': 'es un nuevo usuario',
	'in the following companies and industrial sites': 'en las siguientes empresas y centros de trabajo',
	'with permission level': 'con nivel de permiso',
	'Do you want do confirm the creation?': '¿Quieres confirmar la creación?',
	'User permissions updated successfully': 'Permisos de usuario actualizados exitosamente',
	'User deleted successfully': 'Usuario desactivado exitosamente',
	'User already registered': 'Usuario ya registrado',
	'Delete user': 'Eliminar usuario',
	'You must change user role or at least one premission.':
		'Debes cambiar el nivel de permiso del usuario o al menos un permiso',
	'Heads up! Want to disable this user?': '¡Aviso! ¿Quieres desactivar este usuario?',
	'Heads up! Want to enable this user?': '¡Aviso! ¿Quieres activar este usuario?',
	'You need to make at least one change.': 'Necesitas hacer al menos un cambio.',
	'Failed to fetch workstations': 'Error al obtener puestos de trabajo',
	Carriage: 'Carro',
	Barrow: 'Carretilla de mano',
	Conveyor: 'Cinta transportadora',
	Crane: 'Grúa',

	Activate: 'Activar',
	Deactivate: 'Desactivar',
	Edit: 'Editar',
	'User status updated successfully': 'Estado del usuario actualizado exitosamente',
	'Failed to update user status': 'Error al actualizar el estado del usuario',

	'Your plan has been canceled': 'Tu plan ha sido cancelado',
	'Upgrade your plan and continue using one platform': 'Actualice su plan y continúe usando la plataforma',
	'Your plan has expired': 'Tu plan ha caducado',
	'Renew it to continue using': 'Renovarlo para seguir usándolo',
	'Processed files limit reached': 'Se alcanzó el límite de archivos procesados',
	'in the year': 'en el año',
	'in the month': 'en el mes',
	'Minutes used (Limit)': 'Minutos utilizados (Limite)',
	'Uploaded videos (Limit)': 'Videos cargados (Limite)',
	'Processing limit reached': 'Límite de procesamiento alcanzado',
	Position: 'Cargo',
	'Do you really want to delete': '¿Realmente quieres eliminar',
	'Control period': 'Periodo de control',
	'Enter a control period': 'Ingrese un periodo de control',
	Annually: 'Anual',
	Department: 'Departamento',
	'Enter an department': 'Ingrese un departamento',
	'Maximum minutes of use': 'Minutos máximos de uso',
	'Enter a maximum minutes of use': 'Introduzca un máximo de minutos de uso',
	'Maximum number of videos': 'Número máximo de vídeos',
	'Enter a maximum number of videos': 'Introduzca un número máximo de vídeos',
	'Enter a NIT': 'Ingrese un NIT',
	'Enter a valid NIT': 'Ingrese un NIT válido',
	'Enter a plan': 'Ingrese um plano',
	'Renewal Day': 'Día de renovación',
	'Enter a renewal Day': 'Ingrese un día de renovación',
	'Renewal Month': 'Mes de renovación',
	'Enter a renewal Month': 'Ingrese un mes de renovación',
	'Enter a industrial site': 'Ingresar a un contro de trabajo',
	'Use the same company information': 'Utilice la misma información de la empresa',
	'Enter a city': 'Introduce una ciudad',
	'Set the maximum processing time': 'Establecer el tiempo máximo de procesamiento',
	'Set a maximum number of videos to process': 'Establecer un número máximo de vídeos para procesar',
	'Add a new industrial site': 'Agregar nuevo centro de trabajo',
	'Enter a corporate name': 'Introduzca una razón social',
	'Processing control': 'Control de procesamiento',
	'Use company minutes and/or number of videos': 'Utilizar minutos y/o cantidad de vídeos de la empresa',
	'Company deleted successfully': 'Empresa eliminada exitosamente',
	'New company': 'Nueva empresa',
	'Delete industrial site': 'Eliminar centro de trabajo',
	'the company': 'la empresa',
	'the industrial site': 'el centro de trabajo',
	'Select a value': 'Seleccione un valor',

	'Failed to remove file': 'No se ha podido eliminar el archivo',
	'Video selection': 'Selección de vídeo',
	'Search for a file': 'Busque un archivo',
	Uploader: 'Autor del upload',
	'Start report without a video': 'Iniciar reporte sin video',
	'Select the end date': 'Seleccione la fecha final',
	'Select the start date': 'Seleccione la fecha de inicio',
	'Date cannot larger than start date': 'La fecha no puede ser mayor que la fecha de inicio',
	'Activity created successfully': 'Actividad creada exitosamente',
	Reporting: 'Reporte',
	List: 'Lista',
	'Start date': 'Fecha de inicio',
	'End date': 'Fecha final',
	to: 'a',
	until: 'hasta',
	Activities: 'Actividades',
	'Activity name': 'Nombre de la actividad',
	'Download report': 'Baixar informe',
	'Download video': 'Baixar vídeo',
	'File name': 'Nombre del archivo',
	'Select an activity': 'Seleccione una actividad',
	'Workstation created successfully': 'Estación de trabajo creada con éxito',
	'Preliminary Ergonomic Analysis': 'Análisis Ergonómico Preliminar',
	'Select the risk range': 'Seleccione el intervalo de riesgo',
	'Enter the activity': 'Introduzca la actividad',
	'Select the evaluator': 'Seleccionar al evaluador',

	'Add description': 'Agregar descripción',
	'Action plan': 'Plan de acción',
	'Create actions to address the problems encountered': 'Crea acciones para resolver los problemas encontrados',
	'Edit action plan': 'Editar plan de acción',
	'Action plan generated': 'Plan de acción generado',
	'New action plan': 'Nuevo plan de acción',
	'Describe the situation detected and the preventive measures already implemented (if any)':
		'Describa la situación detectada y las medidas preventivas ya implementadas (si existe alguna)',
	'Generate action plan': 'Generar plan de acción',
	Section: 'Sección',
	'Create actions to address the problems encountered.': 'Crea acciones para resolver los problemas encontrados.',
	Evaluation: 'Evaluación',
	'Pre-set specific characteristics': 'Pre-establecer características específicas',
	Chair: 'Silla',
	'Monitor and Phone': 'Monitor y Teléfono',
	'Mouse and Keyboard': 'Ratón y Teclado',
	'Peripherals and monitor': 'Periféricos y monitor',
	'Final score': 'Puntuación final',
	'Analysis date': 'Fecha de análisis',
	'Enter the date of analysis': 'Ingrese la fecha de análisis',
	'Please enter a report name': 'Por favor, ingrese un nombre de informe',
	'Enter the data related to the new analysis below':
		'Ingrese los datos relacionados con el nuevo análisis a continuación',
	'Other elements': 'Otros elementos',
	'New element': 'Nuevo elemento',
	Priority: 'Prioridad',
	Provide: 'Proporcionar',
	'Failed to create elements': 'Error al crear elementos',
	'Required elements': 'Elementos requeridos',
	'Prioritize the required elements': 'Priorizar los elementos requeridos',
	Maintenance: 'Mantenimiento',
	'Overall results': 'Resultados generales',
	'Upload image': 'Subir imagen',
	'Upload images of the activity being evaluated': 'Subir imágenes de la actividad que se está evaluando',
	Image: 'Imagen',
	Images: 'Imágenes',
	'Do you really want to delete this image?': '¿Realmente desea eliminar esta imagen?',
	'Add file': 'Agregar archivo',
	'General information': 'Información general',

	CHANGE: 'MUDAR',
	MAINTENANCE: 'MANTENIMIENTO',
	PROVIDE: 'FORNECER',

	'Knees at 90°': 'Rodillas en 90°',
	'Very low - Knee angle < 90°': 'Muy bajo - Ángulo de rodilla < 90°',
	'Very high - Knee angle > 90º': 'Muy alto - Ángulo de rodilla > 90º',
	'No foot contact with the floor': 'Sin contacto de pie con el suelo',
	'Insufficient space at the bottom of the desk - No leg mobility':
		'Espacio insuficiente en la parte baja del escritorio - No hay movilidad para las piernas',
	'Not adjustable': 'No ajustable',
	'Approximately 8 centimeters of space between the knee and the edge of the seat or chair':
		'Aproximadamente 8 centímetros de espacio entre la rodilla y el borde del asiento o silla',
	'Very long - Less than 8 centimeters of space': 'Muy largo - Menos de 8 centímetros de espacio',
	'Very short - More than 8 centimeters spacing': 'Muy corto - Más de 8 centímetros de espacio',
	'Elbows supported in line with the shoulder - Shoulders relaxed':
		'Coyunteras apoyadas en línea con el hombro - Hombros relajados',
	'Very high (Shoulders shrugged) / Low (Unsupported arms)':
		'Muy alto (Hombros encogidos) / Bajo (brazos no apoyados)',
	'Very hard or damaged surface': 'Superficie muy dura o dañada',
	'Very wide': 'Muy ancha',
	'Adequate lumbar support - Chair reclines between 95° and 110º':
		'Soporte lumbar adecuado - Silla reclina entre 95° y 110º',
	'No lumbar support or lumbar support not positioned on the lower back':
		'Sin apoyo lumbar o apoyo lumbar no posicionado en la espalda baja',
	'Backrest angle too far back (greater than 110º) or angle too far forward (less than 95º)':
		'Ángulo del respaldo demasiado lejos de atrás (mayor que 110º) o ángulo demasiado lejos hacia adelante (menor que 95º)',
	'Without backrest or lumbar support (e.g. on a stool or leaning forward)':
		'Sin respaldo o soporte lumbar (ex: en una silla o agacharse hacia adelante)',
	'Backless back - Non-adjustable': 'Respaldo de trás - No ajustable',
	'Length or distance measured with the arm (40 to 75 cms) / Screen at eye level':
		'Medida de longitud o distancia medida con el brazo (40 a 75 cms) / Pantalla en nivel de ojo',
	'Very low (Below 30º)': 'Muy bajo (Abajo de 30º)',
	'Far away': 'Muy lejos',
	'Very high (Neck in extension)': 'Muy alto (Pecho en extensión)',
	'Neck rotated more than 30º': 'Pecho rotado más de 30º',
	'Glare on the screen': 'Brillo en la pantalla',
	'No use of lecterns or document holders, if required':
		'Sin uso de púlpitos o guardadores de documentos, si es necesario',
	'Very high (Neck in extension) / Very low (Below 30º)': 'Muy alto (Pecho en extensión) / Muy bajo (Abajo de 30º)',
	'Very high work surface (shrugged shoulders)': 'Superficie de trabajo muy alta (hombros encogidos)',
	'Headphones with microphones, one hand on the phone and neutral neck posture':
		'Auriculares con micrófonos, una mano en el teléfono y postura de pecho neutral',
	'Too far for the range (More than 30cms separation)': 'Muy lejos para la gama (Más de 30cms de separación)',
	'Holding the phone with the neck and shoulder': 'Manteniendo el teléfono con el pecho y el hombro',
	'No hands-free option': 'Sin opción de mano libre',
	'Mouse in line with shoulder': 'Ratón en línea con el hombro',
	'Reaching for the mouse / Arm in abduction': 'Alcanzar el ratón / Brazo en abducción',
	'Mouse and keyboard on surfaces of different levels': 'Ratón y teclado en superficies de diferentes niveles',
	'Gripper grip on the mouse': 'Apertura de garra en el ratón',
	'Wrist rest in front of the mouse': 'Descanso del pulgar en frontal del ratón',
	'Wrists straight - Shoulders relaxed': 'Pulgares rectos - Hombros relajados',
	'Wrists extended - Keyboard at positive angle ( > 15º Extension of wrists)':
		'Pulgares extendidos - Teclado en ángulo positivo ( > 15º Extensión de los pulgares)',
	'Deviation of the wrists during transcription or typing':
		'Desviación de los pulgares durante la transcripción o el tecleo',
	'Keyboard too high, shoulders shrugged': 'Teclado demasiado alto, hombros encogidos',
	'Reaching for items or objects overhead': 'Alcanzar objetos o elementos sobre el cabo',
	'Non-adjustable keyboard platform': 'Plataforma de teclado no ajustable',

	'Element deleted successfully': 'Elemento eliminado con éxito',
	'Image deleted successfully': 'Imagen eliminada con éxito',
	'Image uploaded successfully': 'Imagen cargada con éxito',
	'Rosa report updated successfully!': '¡Actualizado con éxito el informe de Rosa!',
	'Fail to save the comments': 'Error al guardar los comentarios',
	'Error save duration!': '¡Error al guardar la duración!',
	'Error save element!': '¡Error al guardar el elemento!',
	'Image updated successfully': 'Imagen actualizada con éxito',

	chair: 'silla',
	'Chair height': 'Altura de la silla',
	'Chair Height': 'Altura de la silla',
	'Seat depth': 'Profundidad del asiento',
	Armrests: 'Reposabrazos',
	'Seat backrest': 'Respaldo del asiento',
	Mouse: 'Ratón',
	Keyboard: 'Teclado',
	Monitor: 'Monitor',
	Phone: 'Teléfono',
	'Final score ROSA': 'Puntuación final ROSA',
	'Scoring by section': 'Puntuación por sección',
	'Seat Backrest': 'Respaldo del asiento',
	Footrest: 'Respaldos de pie',
	Headband: 'Cinta de cabeza',
	'Fixed monitor base': 'Base de monitor fija',
	'Portable monitor stand': 'Soporte de monitor portátil',
	'Channeling cables': 'Cables de canalización',
	'Priority level of the demand, being 1 medium priority and 2 high priority.':
		'Nivel de prioridad de la demanda, siendo 1 prioridad media y 2 prioridad alta.',
	'Low priority': 'Prioridad baja',
	'Medium priority': 'Prioridad media',
	'High priority': 'Prioridad alta',
	'Select a priority': 'Seleccione una prioridad',
	'Administrative activities analysis': 'Análisis de actividades administrativas',
	'Do you really want to delete this element?': '¿Realmente desea eliminar este elemento?',
	'Select the items you want to present in your document':
		'Seleccione los elementos que desea presentar en su documento',
	'Filling details': 'Completando detalles',
	'Image description': 'Descripción de la imagen',
	'Generate document': 'Generar documento',
	Filename: 'Nombre del archivo',

	'Task already exist': 'Tarea ya existe',
	'Task not created': 'Tarea no creada',

	// OWAS
	'Highest score': 'Pontuación más alta',
	'Activity description': 'Descripcion de la actividad',
	productivity: 'productividad',
	'Number of exposed': 'Número de expuestos',
	'Capture interval': 'Intervalo de captura',
	'in seconds': 'en segundos',
	'Enter the report name': 'Insertar el nombre del informe',
	'Enter the sector': 'Insertar el sector',
	'Enter the evaluator': 'Insertar el evaluador',
	'Enter the analysis date': 'Insertar la fecha de análisis',
	'Enter the activity description': 'Insertar descripción de la actividad',
	'Number of exposed must be greater than 0': 'El número de expuestos debe ser superior a 0',
	'Enter the capture interval': 'Insertar el intervalo de captura',
	'Set the time interval, in seconds, for capturing video frames.':
		'Establezca el intervalo de tiempo, en segundos, para capturar cuadros de vídeo.',

	'Report consolidated successfully': 'Reporto consolidado exitosamente',
	'Report deconsolidated successfully': 'Reporto desconsolidado exitosamente',
	'Report consolidated but PDF not generated': 'Reporto consolidado, pero PDF no generado',

	'Description of the activity': 'Descripción de la actividad',
	'Overall task risk': 'Riesgo global de la actividad',
	Category: 'Categoría',
	Explanation: 'Explicación',
	'No action required': 'No requiere acción',
	'Normal and natural posture without harmful effects on the musculoskeletal system':
		'Postura normal y natural sin efectos dañinos en el sistema músculo-esquelético',
	'Corrective actions required in the near future': 'Se requieren acciones correctivas en un futuro cercano',
	'Posture with the possibility of causing damage to the musculoskeletal system':
		'Postura con posibilidad de causar daño al sistema músculo-esquelético',
	'Corrective actions are required as soon as possible': 'Se requiere acciones correctivas lo antes posible',
	'Posture with harmful effects on the musculoskeletal system':
		'Postura con efectos dañinos sobre el sistema músculo-esquelético',
	'Corrective actions need to be taken immediately': 'Se requiere tomar acciones correctivas inmediatamente',
	'The load caused by this posture has extremely harmful effects on the musculoskeletal system':
		'La carga causada por esta postura tiene efectos sumamente dañinos sobre el sistema músculo-esquelético',
	'Tasks with the most serious risks': 'Tareas con los riesgos más graves',
	'Risk of the tasks': 'Riesgo de las tareas',
	'Manual tools': 'Herramientas manuales',
	'Category 1': 'Categoría 1',
	'Category 2': 'Categoría 2',
	'Category 3': 'Categoría 3',
	'Category 4': 'Categoría 4',
	'Risk by segment and exposure time': 'Riesgo por segmento y tiempo de exposición',
	Torso: 'Espalda',
	'Upper limbs': 'Miembros superiores',
	'Lower limbs': 'Miembros inferiores',
	'Computer vision identified the positions of each task, review and add the required information':
		'La visión por computadora identificó las posiciones de cada tarea, revise y agregue la información necesaria',
	'Search for a task': 'Buscar una tarea',
	'All tasks': 'Todas las tareas',
	'Tasks not analyzed': 'Tareas no analizadas',
	'Task name': 'Nombre de la tarea',
	'Overall risk of the task': 'Riesgo global de la postura:',
	'The colors shown do not refer to the OWAS tool.':
		'Los colores representados no hacen referencia a la herramienta OWAS.',
	'Computer vision does not detect back twisting.': 'La visión por computadora no detecta la torsión de la espalda.',
	Straight: 'Recta',
	Bent: 'Flexionada',
	'Straight and rotated': 'Recta y con giro',
	'Bent and rotated': 'Flexionada y con giro',
	'Two arms down': 'Dos brazos abajo',
	'One arm up': 'Un brazo arriba',
	'Two arms up': 'Dos brazos arriba',
	Sitting: 'Sentado',
	'Standing on two straight legs': 'De pie sobre dos piernas rectas',
	'Standing on one straight legs': 'De pie sobre una pierna estirada',
	'Standing or squatting on two flexed legs': 'De pie o en cuclillas sobre dos piernas flexionadas',
	'Standing or squatting on one bent leg': 'De pie o en cuclillas sobre una pierna flexionada',
	Kneeling: 'Arrodillado',
	Walking: 'Caminando',
	'Task risk': 'Riesgo de la tarea',
	'Less than 10kg': 'Menos de 10 kilogramos',
	'Between 10kg and 20kg': 'Entre 10 y 20 kilogramos',
	'More than 20kg': 'Más de 20 kilogramos',
	'Select the task name': 'Seleccione el nombre de la tarea',
	'Enter the manual tools': 'Insertar las herramientas manuales',

	'Category value not found': 'Valor de categoría no encontrado',
	'Failed to update frame': 'Error al actualizar frame',
	'Frames not created': 'Error al crear frames',
	'Frame not found': 'Frame no encontrado',
	'Frame not updated': 'Frame no actualizado',
	'Frame updated successfully': 'Frame actualizado exitosamente',
	'There is frames not evaluated': 'Hay frames no evaluados',
	'Perform the evaluation of the tables': 'Realice la evaluación de los cuadros',
	'Capture interval should be less than the file duration':
		'El intervalo de captura debe ser menor que la duración del vídeo',

	// Dashboard
	Evaluated: 'Evaluados',
	'Not evaluated': 'No evaluado',
	'Highest risk found': 'Mayor riesgo encontrado',

	'Predetermined time frame': 'Espacio de tiempo predeterminado',
	'Select the time frame': 'Seleccione el espacio de tiempo',
	'Select the checklist': 'Seleccione la lista de verificación',
	'Previous period': 'Período anterior',
	'The period will only be applied to cards that include these variables.':
		'El periodo sólo se aplicará a las tarjetas que incluyan estas variables.',
	'Reference checklist': 'Lista de verificación de referencia',
	'Reference period': 'Período de referencia',
	'Comparison period': 'Período de comparación',
	'Current month': 'Mes actual',
	'Current quarter': 'Trimestre actual',
	'Current semester': 'Semestre actual',
	'Current year': 'Año actual',
	'Previous month': 'Mes anterior',
	'Previous quarter': 'Trimestre anterior',
	'Previous semester': 'Semestre anterior',
	'Previous year': 'Año anterior',
	'Comparison of highest risk found between': 'Comparación del mayor riesgo encontrado entre',
	'Industrial Sites': 'Centro de Trabajo',
	quarter: 'trimestre',
	semester: 'semestre',
	Jan: 'Ene',
	Feb: 'Feb',
	Apr: 'Abr',
	May: 'Mayo',
	Jun: 'Jun',
	Jul: 'Jul',
	Aug: 'Ago',
	Sep: 'Sep',
	Oct: 'Oct',
	Nov: 'Nov',
	Dec: 'Dic',
	D: 'D',
	W: 'S',
	M: 'M',
	'3M': '3M',
	'6M': '6M',
	Y: 'A',
	'Generated reports': 'Informes generados',
	'Number of reports': 'Cantidad de informes',
	'Left side': 'Lado izquierdo',
	'Right side': 'Lado derecho',
	'Exposure by body side': 'Exposición por lado del cuerpo',
	'Exposure Score for all body segments': 'Score de exposición para todos los segmentos corporales',
	'Revised Strain Index ≤10': 'Strain Index Revisado ≤10',
	'Revised Strain Index >10': 'Strain Index Revisado >10',
	'Most frequent movement': 'Movimiento más frecuente',
	'Movement frequency': 'Frecuencia del movimiento',
	'Incidence of risk': 'Incidencia del riesgo',
	'Evolution of the highest risk found': 'Evolución del mayor riesgo encontrado',
	'Action plan status': 'Estado de los planes de acción',
	'No data available.': 'No hay datos disponibles.',
	'Number of actions': 'Número de acciones',
	Late: 'Atrasadas',
	'Not started': 'No iniciadas',
	'This page was not found.': 'No se ha encontrado esta página.',
	'Try Again': 'Intentar de Nuevo'
};

export default dictionary;
